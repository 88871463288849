import dashboard from './dashboard';
import app from './app';
import auth from './auth';
import loading from './loading';
import snackbar from './snackbar';
import chat from './chat';
import notifications from './notifications';
import teleconsultation from './teleconsultation';

export default {
    dashboard,
    app,
    auth,
    loading,
    snackbar,
    chat,
    notifications,
    teleconsultation,
};
