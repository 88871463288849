/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Input, Button, Select, CheckboxToggle,
} from 'react-rainbow-components/components/';
import {
    Email, Lock, User, SignPost,
    Phone, Avatar,
} from '../../components/icons';
import validate from './validate';
import { signUpAsync } from '../../redux/actions/auth';
import { setSnackbar } from '../../redux/actions/snackbar';
import { allCountriesList } from '../../config/select';
import { navigateTo } from '../../history';
import KatomiCGU from '../../components/KatomiCGU';

import './styles.css';

class SignupForm extends React.Component {
    state = {
        email: '',
        password: '',
        nationality: '',
        modalIsOpen: false,
        constCGU: false,
        constTelec: false,
    };

    handleChange = (e) => {
        const {
            constCGU,
            constTelec,
        } = this.state;
        if (e.target.name === 'constCGU' || e.target.name === 'constTelec') {
            if (e.target.name === 'constCGU') {
                this.setState({ [e.target.name]: !constCGU });
            } else {
                this.setState({ [e.target.name]: !constTelec });
            }
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // eslint-disable-next-line consistent-return
    handleSubmit = (e) => {
        const { setSnackbar } = this.props;
        e.preventDefault();
        const body = { ...this.state };
        const { signUpAsync } = this.props;
        if (!body.constCGU) {
            setSnackbar('Veuillez lire et accepter les conditions générales de katomi avant de continuer', 'error');
            return false;
        }
        if (!body.constTelec) {
            setSnackbar('Veuillez approuver votre consentement télémédecine avant de continuer', 'error');
            return false;
        }
        signUpAsync(body);
        // navigateTo('')
    }

    handleOpenModal = () => {
        const { modalIsOpen } = this.state;
        this.setState({ modalIsOpen: !modalIsOpen });
    }

    render() {
        const {
            constCGU,
            constTelec, nationality, modalIsOpen,
        } = this.state;
        return (
            <div className="signup-container">
                <KatomiCGU open={modalIsOpen} handleClose={this.handleOpenModal} />
                <div className="card-container">
                    <Card style={{ paddingTop: '1em' }}
                        icon={<Avatar className="rainbow-rainbow-forms_contact-details-avatar" />}
                        title="Inscription">
                        <div className="rainbow-flex rainbow-align_center is-jura" style={{ margin: '1rem auto', justifyContent: 'center', marginBottom: 0 }}>
                            <p>Vous avez déja un compte? &nbsp; </p>
                            <p style={{ cursor: 'pointer', color: '#01b6f5', fontWeight: 'normal' }} onClick={() => { navigateTo('/login'); }}>Connectez-vous</p>
                        </div>

                        <form noValidate onSubmit={(e) => { this.handleSubmit(e); }}>
                            <article style={{ padding: 50 }}>
                                <div className="control-container">
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<Email />}
                                        component={Input}
                                        name="email"
                                        label="Email"
                                        defaultMessage="Adresse email"
                                        required
                                        type="email" />
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<Email />}
                                        component={Input}
                                        name="emailConfirmation"
                                        label="Confirmer votre Email"
                                        defaultMessage="Confirmation de votre adresse email"
                                        required
                                        type="email" />
                                </div>
                                <div className="control-container">

                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<Lock />}
                                        component={Input}
                                        name="password"
                                        label="Mot de passe"
                                        defaultMessage="Mot de passe"
                                        required
                                        type="password" />
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<Lock />}
                                        component={Input}
                                        name="passwordConfirmation"
                                        label="Confirmer votre mot de passe"
                                        defaultMessage="Mot de passe"
                                        required
                                        type="password" />
                                </div>
                                <div className="control-container">
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<User />}
                                        component={Input}
                                        name="lastName"
                                        label="Nom"
                                        defaultMessage="Nom"
                                        required
                                        type="text" />
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<User />}
                                        component={Input}
                                        name="firstName"
                                        label="Prénom"
                                        defaultMessage="Prénom"
                                        required
                                        type="text" />
                                </div>
                                <div className="control-container">
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<Phone />}

                                        component={Input}
                                        name="cellphone"
                                        label="Téléphone portable"
                                        defaultMessage="Nom"
                                        type="text" />
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small"
                                        icon={<SignPost />}
                                        component={Input}
                                        name="address"
                                        label="Adresse"
                                        defaultMessage="Adresse"
                                        type="text" />
                                </div>
                                <div className="control-container" style={{ margin: '0px 3rem' }}>
                                    <Field
                                        onChange={(e) => { this.handleChange(e); }}
                                        className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                                        options={allCountriesList}
                                        value={nationality}
                                        name="nationality"
                                        label="Pays"
                                        required
                                        placeholder="Pays"
                                        component={Select}
                                    />
                                </div>
                                <div style={{
                                    margin: '1rem 3rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap',
                                }}>
                                    <Field
                                        label="j'ai lu et j'accepte les"
                                        value={constCGU}
                                        name="constCGU"
                                        onChange={(e) => { this.handleChange(e); }}
                                        component={CheckboxToggle}
                                    />

                                    <p style={{ color: '#01b6f5', cursor: 'pointer' }} onClick={this.handleOpenModal}>
                                      &nbsp;  CGU de katomi
                                    </p>
                                </div>
                                <div style={{ margin: '1rem 3rem' }}>
                                    <Field
                                        label="Consentement Télémédecine*"
                                        value={constTelec}
                                        name="constTelec"
                                        onChange={(e) => { this.handleChange(e); }}
                                        component={CheckboxToggle}
                                    />
                                    <p className="is-jura" style={{ fontSize: '0.675rem', margin: '0.4rem' }}>


                                Afin d'utiliser nos services de télémédecine nous recueillons
                                votre consentement sans lequel nous ne pouvons vous offrir
                                 nos services. En choisissant "Oui"
                                 vous acceptez la réalisation d’un acte médical,
                                 la réalisation d’un acte médical
                                  à distance, l’hébergement des données de santé.
                                    </p>
                                </div>

                                <Button
                                    className="rainbow-m-top_medium variant-brand signup-submit-button"
                                    type="submit"
                                    variant="brand">
                                    <span>
                                        Inscription
                                        <i className="fad fa-unlock-alt login-icon-v2" />

                                    </span>
                                </Button>

                            </article>
                        </form>

                    </Card>

                </div>
                <div className="bg-container-sig">
                    <div className="second-division">
                        <img alt="logo katomi" src="/assets/images/katomi-white-logo.png" className="img-kat" />
                        <h6 className="text-kat"> Votre dossier médical sécurisé, accessible ou que vous soyez dans le monde !</h6>

                    </div>

                </div>
                {/* <PasswordRecovery
                    open={modalIsOpen}
                    handleClose={this.handleOpenModal}
                    handleSubmit={this.handleSubmitPasswordReset}
                    handleChange={this.handleChange} /> */}
            </div>

        );
    }
}

SignupForm.propTypes = {
    signUpAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};

SignupForm.defaultProps = {
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        signUpAsync,
        setSnackbar,
    },
    dispatch,
);

export default reduxForm({
    form: 'sign-un-form',
    validate,
})(connect(null, dispatchToProps)(SignupForm));
