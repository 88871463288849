import React from 'react';
import { Card, Input, Button } from 'react-rainbow-components/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    CardElement, useStripe, useElements,
} from '@stripe/react-stripe-js';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSnackbar } from '../../../../redux/actions/snackbar';
import '../../styles.css';

const PaymentForm = (props) => {
    const { user, setSnackbar, handleCheckout } = props;
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            setSnackbar('Carte invalide', 'error');
        } else {
            handleCheckout(result.token);
        }
    };
    return (
        <div>
            <Card
                title="Le prix de la téléconsultation est 25€"

                className="payment-card"
            >

                <div className="checkout-telec-form">
                    <Input label="Nom" value={user.lastName} />
                    <Input label="Prénom" value={user.firstName} />
                    <Input label="Email" value={user.email} />
                    <Input label="Téléphone" value={user.phone} />
                    <CardElement />
                </div>

            </Card>

            <Button
                className="checkout-telec-submit variant-success"
                //  label="Valider"
                variant="success"
                onClick={handleSubmit}>
                <span>Valider</span>
                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 5 }} />
            </Button>
        </div>

    );
};


PaymentForm.propTypes = {
    user: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    handleCheckout: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSnackbar,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(PaymentForm);
