/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    ProgressIndicator,
    ProgressStep,
    Button,
    Modal,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { parsePhoneNumber } from 'react-phone-number-input';
import { setDoctorsAsync } from '../../../redux/actions/chat';
import { updateAsync } from '../../../redux/actions/auth';
import {
    createTelecAsync,
    getDoctorAvailabilityAsync,
    payAvailableTelecAsync,
} from '../../../redux/actions/teleconsultation';
import Contacts from '../../messages/contacts';
import { PENDING } from '../../../config';
import '../styles.css';
import SelectDate from './selectDate';
import PhoneValidation from './phoneValidation';
import TelecRequirements from './requirements';
import PaymentForm from './payment/form';
import DialogSpinner from '../../../components/DialogSpinner';

const stepNames = [
    'select-doctor',
    'select-date',
    'validate-phone',
    'validate-requirements',
    'payment',
];

// TEST ENV
const stripePromise = loadStripe('pk_test_qgkr0EKqf0vIEmZ99vuizvcz00JpB55I46');

class PlanifyTelec extends React.Component {
  state = {
      currentStepIndex: 0,
      date: null,
      doctor: null,
      selectedEvent: null,
      phoneNumber: '',
  };

  async componentDidMount() {
      const { setDoctorsAsync, user } = this.props;
      if (user) {
          setDoctorsAsync({ acces: user.acces });
      }
  }

  componentDidUpdate(oldProps) {
      const { open } = this.props;
      if (oldProps.open !== open && !open) {
      // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ currentStepIndex: 0, date: null, doctor: null });
      }
  }

  setPhoneNumber = (phoneNumber) => {
      this.setState({ phoneNumber });
  };

  handleNext = (data) => {
      const { currentStepIndex, selectedEvent, phoneNumber } = this.state;
      const { getDocAv, user, updateAsync } = this.props;
      const nextStep = currentStepIndex + 1;
      const phoneNumb = `${user.dialingCode}${user.phone}`;
      switch (currentStepIndex) {
          case 0:
              getDocAv(data._id);
              this.setState({
                  doctor: data,
                  currentStepIndex: nextStep,
                  phoneNumber: phoneNumb,
              });
              break;
          case 1:
              this.setState({ currentStepIndex: nextStep });
              break;
          case 2:
              this.setState({ currentStepIndex: nextStep });
              if (phoneNumb !== phoneNumber) {
                  const parsed = parsePhoneNumber(phoneNumber);
                  updateAsync(
                      {
                          dialingCode: `+${parsed.countryCallingCode}`,
                          phone: parsed.nationalNumber,
                      }, null, null, true,
                  );
              }
              break;
          case 3:
              if (selectedEvent) {
                  this.setState({ currentStepIndex: nextStep });
              } else {
                  this.handleSubmit();
              }
              break;
          default:
              break;
      }
  };

  handleSelectEvent = (ev) => {
      this.setState({ selectedEvent: ev });
      this.handleNext();
  };

  handleSubmit = async (token) => {
      const { doctor, date, selectedEvent } = this.state;
      const {
          createTelecAsync,
          payAvailableTelecAsync,
          handleCloseModal,
      } = this.props;

      if (selectedEvent && token) {
          const body = {
              selectedEvent,
              token,
          };
          const res = await payAvailableTelecAsync(body);
          if (res) {
              handleCloseModal();
          }
      } else {
          const body = {
              doctor,
              date,
              status: PENDING,
          };
          createTelecAsync(body);
          handleCloseModal();
      }
      //   handleCloseModal();
  };

  handleBackClick = () => {
      const { currentStepIndex } = this.state;
      const { user } = this.props;
      const previewStepIndex = currentStepIndex - 1;
      const phoneNumber = `${user.dialingCode}${user.phone}`;
      switch (currentStepIndex) {
          case 1:
              this.setState({ currentStepIndex: previewStepIndex, doctor: null });
              break;
          case 2:
              this.setState({
                  currentStepIndex: previewStepIndex,
                  date: null,
                  selectedEvent: null,
                  phoneNumber,
              });
              break;
          case 3:
              this.setState({
                  currentStepIndex: previewStepIndex,
              });
              break;
          case 4:
              this.setState({
                  currentStepIndex: previewStepIndex,
              });
              break;
          default:
              break;
      }
  };

  handleDateChange = (date) => {
      this.setState({ date });
  };

  switchProgress = () => {
      const { doctors, docAvailability } = this.props;
      const { currentStepIndex, date, phoneNumber } = this.state;
      switch (currentStepIndex) {
          case 0:
              return (
                  <div className="contacts-container" style={{ maxWidth: '75%' }}>
                      <p style={{ marginBottom: '1rem' }} className="is-jura">
              Si votre médecin n&apos;apparait pas ici veuillez partager votre
              dossier médical avec lui afin de pouvoir planifier une session de
              téléconsultation &nbsp;
                          <Link to="/permissions">Partager mon dossier médical</Link>
                      </p>
                      <Contacts doctors={doctors} onClick={this.handleNext} />
                  </div>
              );
          case 1:
              return (
                  <div className="contacts-container flex-column">
                      <SelectDate
                          date={date}
                          handleDateChange={this.handleDateChange}
                          docAvailability={docAvailability}
                          handleSelectEvent={this.handleSelectEvent}
                      />
                  </div>
              );
          case 2:
              return (
                  <div className="contacts-container" style={{ maxWidth: '70%' }}>
                      <PhoneValidation
                          phoneNumber={phoneNumber}
                          setPhoneNumber={this.setPhoneNumber}
                      />
                  </div>
              );
          case 3:
              return (
                  <div className="contacts-container" style={{ maxWidth: '70%' }}>
                      <TelecRequirements />
                  </div>
              );
          case 4:
              return (
                  <div
                      className="contacts-container"
                      style={{ alignItems: 'unset', maxWidth: '70%' }}
                  >
                      <Elements stripe={stripePromise}>
                          <PaymentForm handleCheckout={this.handleSubmit} />
                      </Elements>
                  </div>
              );
          default:
              return null;
      }
  };

  showNextButton = () => {
      const { date, currentStepIndex, phoneNumber } = this.state;
      if (currentStepIndex === 1 && date) {
          return true;
      }
      if (currentStepIndex === 2) {
          if (phoneNumber && parsePhoneNumber(phoneNumber)) {
              return true;
          }
      }
      if (currentStepIndex === 3) {
          return true;
      }
      return false;
  };

  render() {
      const { currentStepIndex, selectedEvent } = this.state;
      const { open, handleCloseModal, dialogIsLoading } = this.props;
      return (
          <div className="rainbow-m-bottom_large rainbow-p-bottom_large">
              <Modal
                  isOpen={open}
                  className="add-telec-modal"
                  onRequestClose={handleCloseModal}
                  title="Planification d'une téléconsultation"
              >
                  {dialogIsLoading ? (
                      <DialogSpinner />
                  ) : (
                      <div>
                          <ProgressIndicator currentStepName={stepNames[currentStepIndex]}>
                              <ProgressStep
                                  name="select-doctor"
                                  label="Séléctionner un médecin"
                              />
                              <ProgressStep
                                  name="select-date"
                                  label="Séléctionner la date de la téléconsultation"
                              />
                              <ProgressStep
                                  name="validate-phone"
                                  label="Vérifier vos données"
                              />
                              <ProgressStep
                                  name="validate-requirements"
                                  label="Politiques d'utilisation"
                              />
                              {selectedEvent ? (
                                  <ProgressStep name="payment" label="Paiement" />
                              ) : null}
                          </ProgressIndicator>
                          <div className="rainbow-m-top_xx-large rainbow-align-content_center rainbow-flex_wrap">
                              {this.switchProgress()}
                          </div>
                          {currentStepIndex > 0 ? (
                              <div
                                  style={{ margin: '3em' }}
                                  className="rainbow-m-top_xx-large rainbow-align-content_center rainbow-flex_wrap"
                              >
                                  <Button
                                      label="Retour"
                                      onClick={this.handleBackClick}
                                      variant="brand"
                                      className="rainbow-m-horizontal_medium variant-brand"
                                  />

                                  {currentStepIndex < 4 ? (
                                      <Button
                                          label="Suivant"
                                          onClick={this.handleNext}
                                          variant="brand"
                                          disabled={!this.showNextButton()}
                                          className="rainbow-m-horizontal_medium variant-brand"
                                      />
                                  ) : null}
                              </div>
                          ) : null}
                      </div>
                  )}
              </Modal>
          </div>
      );
  }
}

PlanifyTelec.propTypes = {
    setDoctorsAsync: PropTypes.func.isRequired,
    createTelecAsync: PropTypes.func.isRequired,
    doctors: PropTypes.array,
    open: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getDocAv: PropTypes.func.isRequired,
    docAvailability: PropTypes.array.isRequired,
    payAvailableTelecAsync: PropTypes.func.isRequired,
    updateAsync: PropTypes.func.isRequired,
    dialogIsLoading: PropTypes.bool.isRequired,
};

PlanifyTelec.defaultProps = {
    doctors: [],
};

const stateToProps = state => ({
    doctors: state.chat.doctors,
    user: state.auth.user,
    docAvailability: state.teleconsultation.docAvailability,
    dialogIsLoading: state.loading.dialogIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setDoctorsAsync,
        payAvailableTelecAsync,
        createTelecAsync,
        getDocAv: getDoctorAvailabilityAsync,
        updateAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(PlanifyTelec);
