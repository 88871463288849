/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Button } from 'react-rainbow-components';

import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';


const Documents = (props) => {
    const {
        handleCapture, images, handleDeleteImage, category,
    } = props;
    let { componentRef } = props;
    const [rotationDeg, changeRotation] = useState(0);
    return (
        <div style={{ margin: '2rem' }}>
            <input
                accept="image/*"
                id="add-ph"
                onChange={e => handleCapture(e, category, componentRef)}
                type="file"
                style={{ display: 'none' }}
            />
            <label htmlFor="add-ph" style={{ margin: '1em' }}>
                <div
                    variant="brand"
                    className="image-input variant-brand"
                >
                    <span>
                                            Ajouter un nouveau document
                    </span>
                </div>
            </label>

            {images.length > 0 ? (
                <Button
                    style={{ display: 'block', margin: '1rem auto' }}
                    variant="neutral"
                    onClick={() => { changeRotation(rotationDeg < 270 ? rotationDeg + 90 : 0); }}
                >
                    Faire pivoter
                </Button>
            ) : null}


            <div style={{ marginTop: '1em' }}>
                <ImageGallery
                    id={category}
                    ref={(i) => { componentRef = i; }}
                    items={images}
                    showPlayButton={false}
                    showBullets
                    additionalClass={`rotate-${rotationDeg}`}
                    showFullscreenButton={images.length > 0}
                />
            </div>


            {images.length > 0 ? (
                <Button
                    style={{ display: 'block', margin: '1rem auto' }}
                    variant="brand"
                    className="variant-brand"
                    onClick={() => handleDeleteImage(
                        category,
                        componentRef.getCurrentIndex(),
                        componentRef,
                    )
                    }
                >
                    Supprimer ce document
                </Button>
            ) : null}
            {' '}
        </div>
    );
};


Documents.propTypes = {
    handleCapture: PropTypes.func.isRequired,
    handleDeleteImage: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    category: PropTypes.string.isRequired,
    componentRef: PropTypes.node.isRequired,
};

export default Documents;
