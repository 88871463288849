/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-rainbow-components/components/Spinner';
import {
    VideoChat,
    Consultation,
    CalendarIcon,
    IncompletedTasksIcon,
} from '../../components/icons';
import Tile from '../../components/Tile';
import { fetchDashboardData } from '../../redux/actions/dashboard';
import { setConversationsListAsync } from '../../redux/actions/chat';
import './styles.css';
import DashboardCard from './dashbordCard';
import history from '../../history';

const ContentPage = (props) => {
    const {
        isLoading,
        nextTelec,
        lastTelec,
        subscriptionType,
        subscriptionExpires,
    } = props;
    if (isLoading) {
        return <Spinner size="large" />;
    }
    return (
        <div>
            <section className="react-rainbow-admin-dashboard_section rainbow-p-top_large align-stretch-center">
                <Tile
                    title="Type d'abonnement"
                    label={subscriptionType}
                    icon={
                        <Consultation className="react-rainbow-admin-dashboard_tile-icon" />
                    }
                />
                <Tile
                    title="Abonnement valide au"
                    label={subscriptionExpires}
                    icon={
                        <IncompletedTasksIcon className="react-rainbow-admin-dashboard_tile-icon" />
                    }
                />

                <Tile
                    title="Prochaine téléconsltation"
                    label={nextTelec}
                    icon={
                        <CalendarIcon className="react-rainbow-admin-dashboard_tile-icon" />
                    }
                />

                <Tile
                    title="Derniére téléconsultation"
                    label={lastTelec}
                    icon={
                        <VideoChat className="react-rainbow-admin-dashboard_tile-icon" />
                    }
                />
            </section>

            <section style={{ alignItems: 'stretch' }} className="rainbow-flex_wrap react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <DashboardCard
                    title="Mon dossier médical"
                    handleClick1={() => { history.push('/medical-record'); }}
                    buttonLabel="Aller à mon dossier médical"
                    imageSRC="https://media.discordapp.net/attachments/690609861301633085/699725861947048086/medical_file.png?width=500&height=500"
                />
                <DashboardCard
                    title="Messagerie"
                    handleClick1={() => { history.push('messages'); }}
                    variant="success"
                    buttonLabel="Consulter ma messagerie"
                    imageSRC="https://image.flaticon.com/icons/svg/2181/2181784.svg"
                />


            </section>
            <section style={{ alignItems: 'stretch' }} className="rainbow-flex_wrap react-rainbow-admin-dashboard_section rainbow-align-content_space-between rainbow-p-top_large">
                <DashboardCard
                    title="Téléconsultations"
                    handleClick1={() => { history.push('/teleconsultation', { addFromDashboard: true }); }}
                    handleClick2={() => { history.push('/teleconsultation'); }}
                    buttonLabel="Planifier une téléconsultation"
                    variant="warning"
                    secondButtonLabel="Voir mon agenda de téléconsultation"
                    imageSRC="https://image.flaticon.com/icons/svg/2181/2181790.svg"
                />
                <DashboardCard
                    title="Permissions d'accés a mon dossier"
                    handleClick1={() => { history.push('/permissions'); }}
                    handleClick2={() => { history.push('/permissions', { addFromDashboard: true }); }}
                    secondButtonLabel="Autoriser un médecin à acceder a mon dossier médical"
                    variant="danger"
                    buttonLabel="Voir la liste des permissions"
                    imageSRC="https://media.discordapp.net/attachments/690609861301633085/699725858713370674/access.png?width=500&height=500"
                />


            </section>


        </div>
    );
};

ContentPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    nextTelec: PropTypes.string.isRequired,
    lastTelec: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    subscriptionExpires: PropTypes.string.isRequired,
};

class Dashboard extends Component {
    componentDidMount() {
        const { fetchDashboardData, setConversationsListAsync } = this.props;
        fetchDashboardData();
        setConversationsListAsync(true);
    }

    render() {
        return (
            <div className="react-rainbow-admin-dashboard_view-port">
                <ContentPage {...this.props} />
            </div>
        );
    }
}

Dashboard.propTypes = {
    fetchDashboardData: PropTypes.func.isRequired,
    setConversationsListAsync: PropTypes.func.isRequired,
};

function stateToProps(state) {
    return state.dashboard;
}

function dispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchDashboardData,
            setConversationsListAsync,
        },
        dispatch,
    );
}

export default connect(
    stateToProps,
    dispatchToProps,
)(Dashboard);
