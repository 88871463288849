import {
    SET_NOTIFICATIONS,
    SET_UNSEEN_MESSAGES,
} from '../../actions/notifications';

const initialState = {
    notifications: [],
    unseenMessages: [],
};

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return { ...state, notifications: action.payload };

        case SET_UNSEEN_MESSAGES:
            return { ...state, unseenMessages: action.payload };
        default:
            return state;
    }
};

export default notifications;
