/* eslint-disable no-underscore-dangle */
import { DateTime } from 'luxon';
import * as TeleconsultAPI from '../../../api/teleconsultation';
import { setSnackbar } from '../snackbar';
import {
    PAYMENT_PENDING,
    REJECTED,
    paymentErrorCodes,
    ENDED,
    STARTED,
} from '../../../config';
import { sessionExpired } from '../auth';
import { createNotification } from '../../../api/notifications';
import { setDialogIsLoading } from '../loading';
import { parseAction } from './logsHelper';

export const SET_TELECONSULTATION_LIST = 'SET_TELECONSULTATION_LIST';
export const SELECT_TELECONSULTATION = 'SELECT_TELECONSULTATION';
export const ADD_TELECONSULTATION = 'ADD_TELECONSULTATION';
export const UPDATE_TELECONSULTATION = 'UPDATE_TELECONSULTATION';
export const DELETE_TELECONSULTATION = 'DELETE_TELECONSULTATION';
export const SET_DOC_AVAILABILITY = 'SET_DOC_AVAILABILITY';

export const createTeleconsultation = value => ({
    type: ADD_TELECONSULTATION,
    payload: value,
});
export const selectTeleconsultation = value => ({
    type: SELECT_TELECONSULTATION,
    payload: value,
});
export const setTeleconsultationList = value => ({
    type: SET_TELECONSULTATION_LIST,
    payload: value,
});
export const updateTeleconsultation = value => ({
    type: UPDATE_TELECONSULTATION,
    payload: value,
});

export const deleteTeleconsultation = value => ({
    type: DELETE_TELECONSULTATION,
    payload: value,
});

export const setDocAvailability = value => ({
    type: SET_DOC_AVAILABILITY,
    payload: value,
});

export const getDoctorAvailabilityAsync = doctorId => async (dispatch) => {
    dispatch(setDialogIsLoading(true));
    try {
        const response = await TeleconsultAPI.getDoctorAvailability(doctorId);
        if (response.data) {
            dispatch(setDocAvailability(response.data));
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err) && err) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
    dispatch(setDialogIsLoading(false));
};

export const payAvailableTelecAsync = body => async (dispatch) => {
    dispatch(setDialogIsLoading(true));
    try {
        const response = await TeleconsultAPI.payAvailableTelec(body);
        dispatch(updateTeleconsultation(response.data));
        dispatch(setSnackbar('Téléconsultation réservée avec succès', 'success'));
        const patientName = `${response.data.patient.lastName} ${response.data.patient.firstName}`;
        // TODO ADD DATE TO THE NOTIF CONTENT
        const notifContent = `${patientName} à reservé une session de téléconsultation prévu le`;
        const notification = {
            user: response.data.doctor._id,
            content: notifContent,
            type: 'teleconsultation',
        };
        createNotification(notification);
        dispatch(setDialogIsLoading(false));
        return 'done';
    } catch (err) {
        if (err.response && err.response.data) {
            const { code } = err.response.data;
            const errorMessage = paymentErrorCodes[code];
            if (errorMessage) {
                dispatch(setSnackbar(errorMessage, 'error'));
            }
        } else if (!sessionExpired(dispatch, err) && err) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
        dispatch(setDialogIsLoading(false));
        return null;
    }
};

export const payPlanedTelecAsync = body => async (dispatch) => {
    dispatch(setDialogIsLoading(true));
    try {
        const response = await TeleconsultAPI.payPlanedTelec(body);
        dispatch(updateTeleconsultation(response.data));
        dispatch(setSnackbar('Téléconsultation réservée avec succès', 'success'));
        const patientName = `${response.data.patient.lastName} ${response.data.patient.firstName}`;
        // TODO ADD DATE TO THE NOTIF CONTENT
        const notifContent = `${patientName} à payé la session de téléconsultation prévu le `;
        const notification = {
            user: response.data.doctor._id,
            content: notifContent,
            type: 'teleconsultation',
        };
        createNotification(notification);
        dispatch(setDialogIsLoading(false));
        return 'done';
    } catch (err) {
        if (err.response && err.response.data) {
            const { code } = err.response.data;
            const errorMessage = paymentErrorCodes[code];
            if (errorMessage) {
                dispatch(setSnackbar(errorMessage, 'error'));
            }
        } else if (!sessionExpired(dispatch, err) && err) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
        dispatch(setDialogIsLoading(false));
        return null;
    }
};

export const createTelecAsync = data => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.createTeleconsultation(data);
        if (response.data) {
            dispatch(createTeleconsultation(response.data));
            dispatch(
                setSnackbar('Téléconsultation planifiée avec succès', 'success'),
            );
            const patientName = `${response.data.patient.lastName} ${response.data.patient.firstName}`;
            const notifContent = `${patientName} vous invite à une session de téléconsultation`;
            const notification = {
                user: data.doctor._id,
                content: notifContent,
                type: 'teleconsultation',
            };
            createNotification(notification);
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err) && err) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const setTelecListAsync = () => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.getTeleconsultations();
        if (response.data) {
            dispatch(setTeleconsultationList(response.data));
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const updateTelecAsync = (id, data) => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.update(id, data);
        if (response.data) {
            if (data.status !== STARTED && data.status !== ENDED) {
                dispatch(updateTeleconsultation(response.data));
                dispatch(setSnackbar('Modification effectué avec succès', 'success'));
                let content;
                switch (data.status) {
                    case PAYMENT_PENDING:
                        content = `${data.patientFullName} à confirmé sa participation à la session de téléconsultation`;
                        break;
                    case REJECTED:
                        content = `${data.patientFullName} ne peut confirmer sa participation à la session de téléconsultation`;
                        break;
                    default:
                        content = '';
                        break;
                }

                const notifBody = {
                    user: data.doctor,
                    type: 'teleconsultation',
                    content,
                };
                createNotification(notifBody);
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const deleteTelecAsync = id => async (dispatch) => {
    try {
        const response = await TeleconsultAPI.remove(id);
        if (response.data) {
            const { isIncoming } = response.data;
            dispatch(deleteTeleconsultation({ _id: id, isIncoming }));
            dispatch(setSnackbar('Téléconsultation annulée avec succès', 'success'));

            if (isIncoming) {
                const telecDate = DateTime.fromMillis(
                    new Date(response.data.date).getTime(),
                ).toFormat("dd/MM/yyyy 'à' HH':'mm ");
                const patientName = `${response.data.patient.lastName} ${response.data.patient.firstName}`;
                const notifContent = `${patientName} a annulé la session de téléconsultation prévu le ${telecDate}`;
                const notification = {
                    user: response.data.doctor._id,
                    content: notifContent,
                    type: 'teleconsultation',
                };
                createNotification(notification);
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar("Une erreur s'est produite !", 'error'));
        }
    }
};

export const postLog = async (id, actionType, actionData, patientMeta) => {
    try {
        const log = {
            action: parseAction(actionType),
            actionData,
            logTime: Date.now(),
            userType: 'patient',
        };
        const body = {
            log,
            patientMeta,
        };
        await TeleconsultAPI.postLog(id, body);
    } catch (err) {
    // eslint-disable-next-line no-console
        console.log(actionType, 'error occured on posting log');
    }
};
