/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    AccordionSection,
    Accordion,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import detectRTC from 'detectrtc';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck, faTimes, faListAlt,
} from '@fortawesome/free-solid-svg-icons';
import { setSnackbar } from '../../../redux/actions/snackbar';
import RTCBrowserCompatibility from './RTCBrowserCompatibility';
import {
    ALLOW_BC,
    ALLOW_ER_PERMISSION,
    ALLOW_ER_NOTFOUND,
} from '../../../redux/actions/teleconsultation/logsHelper';
import { postLog } from '../../../redux/actions/teleconsultation';
import './styles.css';

// eslint-disable-next-line consistent-return
const parseStatus = (isAvailable, havePermission, mic) => {
    if (!isAvailable) {
        return mic ? 'Non détecté' : 'Non détectée';
    }
    if (isAvailable && !havePermission) {
        return 'Autorisation non accordée';
    }
    if (isAvailable && havePermission) {
        return 'Ok';
    }
};

// TODO TEST with a mobile browser to see isWebRTCSupport func status
const CheckMedia = (props) => {
    const [browserStatus, setBrowserStatus] = useState(null);
    const [micStatus, setMicStatus] = useState('Autorisation non accordée');
    const [camStatus, setCamStatus] = useState('Autorisation non accordée');

    useEffect(() => {
        const browserStatus = detectRTC.isWebRTCSupported
            ? 'Compatible'
            : 'Non Compatible';
        setBrowserStatus(browserStatus);
    });

    const {
        open, handleClose, setLocalStream, setSnackbar,
        doctorFullName, selectedTelecId,
    } = props;

    const getLocalStream = async () => {
        try {
            const localStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
            });
            postLog(selectedTelecId, ALLOW_BC);
            setLocalStream(localStream);
        } catch (err) {
            console.log('ERROR ON GETTING STREAM', { err });
            switch (err.name) {
                case 'NotFoundError':
                    // TODO TEST IF WITH WEBCAM OR MIC ONLY THIS ERROR OCCURS
                    setSnackbar('Micro et caméra non detectés', 'error');
                    setMicStatus(parseStatus(false, false, true));
                    setCamStatus(parseStatus(false, false, false));
                    postLog(selectedTelecId, ALLOW_ER_NOTFOUND, { ...err });
                    break;
                case 'NotAllowedError':
                    // TODO TEST IF WITH WEBCAM OR MIC ONLY THIS ERROR OCCURS
                    setSnackbar("Veuillez autoriser l'accés a votre micro et caméra afin de continuer", 'error');
                    setMicStatus(parseStatus(true, false, true));
                    setCamStatus(parseStatus(true, false, false));
                    postLog(selectedTelecId, ALLOW_ER_PERMISSION, { ...err });
                    break;
                default: break;
            }
        }
    };

    const isBrowserCompatible = browserStatus === 'Compatible';
    const isMicOk = micStatus === 'Ok';
    const isCamOk = camStatus === 'Ok';
    return (
        <div className="rainbow-m-around_large">
            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                title={`Votre téléconsultation avec docteur ${doctorFullName}`}
                className="check-media-modal"
            >
                <h3 className="check-media-title">Comment ça marche ? </h3>
                <p style={{ fontSize: '1rem' }} className="is-josefin">
                    <span className="check-media-error" style={{ fontSize: '1rem' }}>
            *
                        {' '}
                    </span>
          Votre navigateur doit supporter l&apos;échange audio/vidéo
                    <Accordion>
                        <AccordionSection
                            icon={(
                                <FontAwesomeIcon
                                    icon={faListAlt}
                                    className="rainbow-color_brand"
                                />
                            )}
                            label="Liste des navigateurs supportés"
                            style={{ margin: 'auto 3rem' }}
                        >
                            <RTCBrowserCompatibility />
                        </AccordionSection>
                    </Accordion>
                </p>
                <p style={{ fontSize: '1rem' }} className="is-josefin">
                    <span className="check-media-error" style={{ fontSize: '1rem' }}>
            *
                        {' '}
                    </span>
          Vérifier que votre microphone et votre caméra sont branchés à votre
          ordinateur
                </p>
                <p style={{ fontSize: '1rem' }} className="is-josefin">
                    <span className="check-media-error" style={{ fontSize: '1rem' }}>
            *
                        {' '}
                    </span>
          Vous devez autoriser l&apos;accés a votre microphone et a votre caméra
                </p>

                <table className="check-media-status-container">
                    <tr>
                        <td className="check-media-status-item">  Etat de votre navigateur :</td>
                        <td className={classNames('check-media-success', {
                            'check-media-error': !isBrowserCompatible,
                        })}>
                            {browserStatus}
                            {' '}
                            {isBrowserCompatible ? (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faTimes} />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="check-media-status-item">  Etat du micro : </td>
                        <td className={classNames('check-media-success', {
                            'check-media-error': !isMicOk,
                        })}>

                            {micStatus}
                            {isMicOk ? (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faTimes} />
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td className="check-media-status-item">
                        Etat de la caméra :
                        </td>
                        <td className={classNames('check-media-success', {
                            'check-media-error': !isCamOk,
                        })}>
                            {camStatus}
                            {isCamOk ? (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faCheck} />
                            ) : (
                                <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faTimes} />
                            )}
                        </td>
                    </tr>
                </table>

                <Button
                    variant="brand"
                    className="variant-brand"
                    style={{
                        display: 'block', margin: '1rem auto', letterSpacing: 1, fontSize: '1.2rem',
                    }}
                    onClick={() => getLocalStream()}>
                    Autoriser l&apos;accés
                    <FontAwesomeIcon style={{ marginLeft: 3 }} icon={faCheck} />

                </Button>
            </Modal>
        </div>
    );
};

CheckMedia.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setLocalStream: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    doctorFullName: PropTypes.string.isRequired,
    selectedTelecId: PropTypes.string.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators({
    setSnackbar,
}, dispatch);


export default connect(null, dispatchToProps)(CheckMedia);


/*

 detectRTC.DetectLocalIPAddress((cb) => {
            console.log(cb || 'oooo');
        });
        console.log(
            {

                isWebsiteHasMicrophonePermissions: detectRTC.isWebsiteHasMicrophonePermissions,
                isWebsiteHasWebcamPermissions: detectRTC.isWebsiteHasWebcamPermissions,
                osName: detectRTC.osName,
                osVersion: detectRTC.osVersion,
                browser: detectRTC.browser,
            },

        );


*/
