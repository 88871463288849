import React from 'react';
import classnames from 'classnames';
import { Input } from 'react-rainbow-components';
import PropTypes from 'prop-types';

export default function UserField(props) {
    const {
        label, value, className, readOnly, name, onChange, ...rest
    } = props;

    const getClassNames = () => classnames('react-rainbow-admin-user-detail_field', className);

    return (
        <div className={getClassNames()} {...rest}>
            <span className="react-rainbow-admin-user-detail_field-label">{label}</span>
            <Input name={name} onChange={(e) => { onChange(e); }} className="react-rainbow-admin-user-detail_field-value" readOnly={readOnly} value={value} />
        </div>
    );
}

UserField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
};

UserField.defaultProps = {
    label: undefined,
    value: undefined,
    className: undefined,
    readOnly: true,
    onChange: () => {},
    name: '',
};
