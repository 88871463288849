import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';


const RTCBrowserCompatibility = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', margin: '1rem' }}>
       Desktop : &nbsp;
                <FontAwesomeIcon
                    icon={faDesktop}
                    className="rainbow-color_brand" />
            </div>

            <p>Google Chrome version 28 ou plus  </p>
            <p>Mozilla Firefox version 22 ou plus</p>
            <p>Microsoft Edge version 12 ou plus</p>
            <p>Safari version 11 ou plus</p>
            <p>Opera version 18 ou plus</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', margin: '1rem' }}>

            Mobile : &nbsp;
                <FontAwesomeIcon icon={faMobileAlt} className="rainbow-color_brand" />
            </div>
            <span style={{ fontWeight: 'bolder' }}> Android: </span>
            <li>    Google Chrome 28 ou plus</li>
            <li> Mozilla Firefox 24 ou plus</li>
            <li>Opera Mobile 12 ou plus </li>
            <span style={{ fontWeight: 'bolder' }}>IOS: </span>
            <li>  MobileSafari iOS 11 ou plus </li>

        </div>
    </div>
);


export default RTCBrowserCompatibility;
