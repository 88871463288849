/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Card } from 'react-rainbow-components/components';


const PhoneValidation = () => (
    <div>

        <Card
            title="Politique d'utilisation"
            className="react-rainbow-admin-user-detail_card add-telec-phone-step"
        >

            <div className="requirements-list phone-validation-form telecav-notice-content">
                <div className="telecav-notice-elem">
                    <p>
Aucun certificat médical ne peut être délivré lors
                    d’une téléconsultation
                    </p>
                </div>
                <div className="telecav-notice-elem">
                    <p>
                Aucun traitement “sensible” ne peut être délivré lors de votre téléconsultation
                    </p>
                </div>
                <div className="telecav-notice-elem">
                    <p>
                En cas d’annulation de votre téléconsultation
                 par votre médecin, le tarif de la téléconsultation vous sera
                  intégralement remboursé
                    </p>
                </div>
                <div className="telecav-notice-elem">
                    {' '}
                    <p>
                En cas d’annulation de votre part,le tarif de la
                 téléconsultation vous sera remboursé moins des frais
                  d'annulation d’un montant de 5 euros.

                    </p>
                </div>
            </div>

        </Card>
    </div>

);


export default (PhoneValidation);
