import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import history from './history';
import Dashboard from './sections/dashboard';
import MedicalRecord from './sections/medicalRecord';
import Messages from './sections/messages';
// import ComingSoon from './sections/comingSoon';
import Login from './sections/login';
import Signup from './sections/signup';
import PermissionsPage from './sections/permissions';
import ProfilePage from './sections/profile';
import TeleconsultationPage from './sections/teleconsultation';
import EmailVerification from './sections/emailVerification';

export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/messages" component={Messages} />
                <Route path="/medical-record" exact component={MedicalRecord} />
                <Route path="/permissions" component={PermissionsPage} />

                {/* <Route path="/c-purposes/add" component={AddCPurposes} /> */}
                {/* <Route path="/c-purposes/:id" component={AddCPurposes} /> */}
                <Route path="/profile" component={ProfilePage} />
                <Route path="/teleconsultation" component={TeleconsultationPage} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/unconfirmed" component={EmailVerification} />

            </Switch>
        </Router>
    );
}
