/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table,
} from 'react-rainbow-components';
// import { DateTime } from 'luxon';
import TablePagination from '../TablePagination';
import AddAntecedent from './add';
// TODO CHANGE THIS ROUTE WHEN CHANGING THE FILE PLACEMENT
import TableActionDelete from '../../sections/permissions/permissionsTable/permissionsTableButtons';
// const formatDate = (date) => DateTime.fromMillis(date);


// const DeleteAntecedent = ({ handleDeleteAntecedents, ...props }) => (
//     <Button label="Supprimer" variant="destructive" onClick={() => { handleDeleteAntecedents(props); }} />
// );

// DeleteAntecedent.propTypes = {
//     // value: PropTypes.number.isRequired,
// };

// {withAddOption ? (
//     <Column header="Actions" field="index" component={DeleteAntecedent} handleDeleteAntecedents={handleDeleteAntecedents} />
// ) : null}

const PatientAntecedentsTable = (props) => {
    const [activePage, setActivePage] = useState(1);

    const getTableData = () => {
        const { tableData } = props;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;
        return tableData.slice(firstItem, lastItem);
    };

    const getPages = () => {
        const { tableData } = props;
        return Math.ceil(tableData.length / 8);
    };

    const {
        addProps,
        handleAntecedentChange,
        handleAddToDataTable,
        handleRadioChange,
        medicalSelectOptions,
        allergiesSelectOptions,
        showTreatment,
        withAddOption,
        globalTab,
        handleDeleteAntec,
        // handleDeleteAntecedents,
    } = props;
        //  const { activePage } = state;
    return (
        <div>
            <div className="react-rainbow-admin-users_tab-content">
                {withAddOption ? (
                    <AddAntecedent
                        globalTab={globalTab}
                        {...addProps}
                        medicalSelectOptions={medicalSelectOptions}
                        allergiesSelectOptions={allergiesSelectOptions}
                        handleChange={handleAntecedentChange}
                        handleAdd={handleAddToDataTable}
                        handleRadioChange={handleRadioChange} />
                ) : null}

                <Table resizeColumnDisabled data={getTableData()} keyField="index" emptyDescription="Aucune donnée a afficher" emptyTitle="">
                    <Column header="Nature" field="nature" />
                    <Column header="Type" field="type" />
                    <Column header="Date" field="date" />
                    <Column header="Observation" field="observation" />
                    {showTreatment ? (
                        <Column header="Traitement" field="traitement" />
                    ) : null}
                    {withAddOption ? (
                        <Column header="Action" field="ind" component={TableActionDelete} handleDeleteDialog={handleDeleteAntec} />

                    ) : null}


                </Table>
                <TablePagination
                    pages={getPages()}
                    activePage={activePage}
                    onChange={setActivePage} />

            </div>
        </div>
    );
};
// }


PatientAntecedentsTable.propTypes = {
    tableData: PropTypes.array.isRequired,
    addProps: PropTypes.object.isRequired,
    handleAntecedentChange: PropTypes.func.isRequired,
    handleAddToDataTable: PropTypes.func.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    medicalSelectOptions: PropTypes.array,
    allergiesSelectOptions: PropTypes.array,
    showTreatment: PropTypes.bool,
    withAddOption: PropTypes.bool,
    globalTab: PropTypes.string.isRequired,
    handleDeleteAntec: PropTypes.func.isRequired,
};

PatientAntecedentsTable.defaultProps = {
    medicalSelectOptions: [],
    allergiesSelectOptions: [],
    showTreatment: true,
    withAddOption: true,
};

export default PatientAntecedentsTable;
