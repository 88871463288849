/* eslint-disable no-underscore-dangle */
import * as ChatAPI from '../../../api/chat';
import { sessionExpired } from '../auth';
import { createNotification } from '../../../api/notifications';

export const SELECT_CONVERSATION = 'SELECT_CONVERSATION';
export const SELECT_USER = 'SELECT_USER';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const SET_DOCTORS = 'SET_DOCTORS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';

export const addConversation = value => ({
    type: ADD_CONVERSATION,
    payload: value,
});

export const selectConversation = value => ({
    type: SELECT_CONVERSATION,
    payload: value,
});
export const selectUser = value => ({
    type: SELECT_USER,
    payload: value,
});
export const setConversationsList = value => ({
    type: SET_CONVERSATIONS,
    payload: value,
});
export const updateConversationList = value => ({
    type: UPDATE_CONVERSATION,
    payload: value,
});
export const setDoctors = value => ({
    type: SET_DOCTORS,
    payload: value,
});

export const selectConversationAsync = id => async (dispatch) => {
    try {
        const response = await ChatAPI.getConversationById(id);
        if (response) {
            dispatch(selectConversation(response.data));
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
};

export const setSelectUser = data => async (dispatch) => {
    try {
        let response;
        if (data) {
            response = await ChatAPI.getConversationByUsersIds({
                u2: data._id,
            });
        }
        dispatch(selectUser(data));
        if (response && response.data) {
            dispatch(selectConversation(response.data));
            if (response.data.isUpdated) {
                const newConvHeader = { ...response.data };
                const LastMsg = response.data.messages[response.data.messages.length - 1];
                newConvHeader.messages = [LastMsg];
                dispatch(updateConversationList(newConvHeader));
            }
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
};

export const setConversationsListAsync = () => async (dispatch) => {
    try {
        const convResponse = await ChatAPI.getAllConversations();
        if (convResponse.data) {
            dispatch(setConversationsList(convResponse.data));
            if (Object.values(convResponse.data).length) {
                const data = Object.values(convResponse.data)[0];
                const firstUser = {
                    _id: data.user,
                    ...data.user_info,
                };
                dispatch(setSelectUser(firstUser));
            }
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
};

// eslint-disable-next-line
export const postMessageAsync = (data, { receiver, receiver_info }, withSelectConv) => async (dispatch) => {
    try {
        const resp = await ChatAPI.postMessage(data);
        if (resp.data) {
            await createNotification({
                type: 'message',
                date: Date.now(),
                user: receiver,
                user_info: receiver_info,
                conversationId: resp.data._id,
            });
            if (withSelectConv) {
                dispatch(selectConversationAsync(resp.data._id));
                dispatch(addConversation({ [receiver]: resp.data }));
            } else {
                const newMsgs = [resp.data.messages[resp.data.messages.length - 1]];
                const conv = { ...resp.data, messages: [...newMsgs] };
                dispatch(updateConversationList(conv));
            }
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
};


export const setDoctorsAsync = body => async (dispatch) => {
    try {
        const doctorsResponse = await ChatAPI.getDoctors(body);
        if (doctorsResponse.data) dispatch(setDoctors(doctorsResponse.data));
    } catch (err) {
        sessionExpired(dispatch, err);
    }
};
