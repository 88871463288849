import React from 'react';
import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';
import PatientDocuments from '../../components/PatientDocuments';
import './styles.css';

const switchTab = (tab, images, handleCapture, handleDeleteImage) => {
    let bioRef;
    let radioRef;
    let autresRef;
    switch (tab) {
        case 'bio': return <PatientDocuments category="bio" handleCapture={handleCapture} handleDeleteImage={handleDeleteImage} images={images.bio} componentRef={bioRef} />;
        case 'radio': return <PatientDocuments category="radio" handleCapture={handleCapture} handleDeleteImage={handleDeleteImage} images={images.radio} componentRef={radioRef} />;
        case 'autres': return <PatientDocuments category="autres" handleCapture={handleCapture} handleDeleteImage={handleDeleteImage} images={images.autres} componentRef={autresRef} />;
        default: return <h1>default</h1>;
    }
};


const Documents = (props) => {
    const {
        currentTab, handleOnSelect, handleCapture,
        handleDeleteImage, images,
    } = props;
    return (
        <div>
            <Tabset
                id="tabset-1"
                onSelect={(ev, tab) => handleOnSelect(tab, 'documents')}
                activeTabName={currentTab}
                className="rainbow-background-color_gray-1 rainbow-p-horizontal_x-large"
            >
                <Tab
                    label="Examens Biologiques"
                    name="bio"
                    id="bio"
                    ariaControls="bioTab"
                />
                <Tab
                    label="Examens Radiologiques"
                    name="radio"
                    id="radio"
                    ariaControls="radioTab"
                />
                <Tab
                    label="Autres Documents"
                    name="autres"
                    id="autres"
                    ariaControls="autresTab" />
            </Tabset>
            <div style={{ maxWidth: '80vw' }}>
                {switchTab(currentTab, images, handleCapture, handleDeleteImage)}
            </div>
        </div>
    );
};

Documents.propTypes = {
    currentTab: PropTypes.string.isRequired,
    handleOnSelect: PropTypes.func.isRequired,
    handleCapture: PropTypes.func.isRequired,
    handleDeleteImage: PropTypes.func.isRequired,
    images: PropTypes.object.isRequired,
};

export default Documents;
