/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal, Input, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Peer from 'peerjs';
import SelectedContact from '../../messages/selectedContact';
import ChatMessages from '../../messages/chatMessages';
import './styles.css';
import { getUserStatus } from '../../../api/teleconsultation';
import CheckMedia from './checkMedia';
import * as logActions from '../../../redux/actions/teleconsultation/logsHelper';
import { postLog, updateTelecAsync } from '../../../redux/actions/teleconsultation';
import { ENDED } from '../../../config';

class StartContainer extends React.Component {
  state = {
      userStatus: 'disconnected',
      messages: [],
      msgToSend: '',
      conn: null,
      localStream: null,
      openCheckMediaDialog: true,
      mic: true,
      cam: true,
  };

  componentDidMount() {
      const { user, selectedTelec } = this.props;
      const peer = new Peer(user._id, { host: 'p2p.katomi.co', secure: true });
      this.setState({ peer });
      peer.on('open', () => {
          postLog(selectedTelec._id, logActions.PEER_OPEN);
      });
      peer.on('error', (err) => {
          postLog(selectedTelec._id, logActions.PEER_ERROR, { err });
      });
  }

  componentDidUpdate(oldProps, oldState) {
      const { localStream } = this.state;

      if (oldState.localStream !== localStream && localStream) {
          const videoCaller = document.querySelector('#caller');
          videoCaller.srcObject = localStream;
          videoCaller.volume = 0;
          this.setUserStatus();
      }
  }

  componentWillUnmount() {
      const { peer } = this.state;
      if (peer) {
          peer.destroy();
      }
  }

  endCall = () => {
      const { updateTelecAsync, selectedTelec, handleClose } = this.props;
      const { peer } = this.state;
      const { _id } = selectedTelec;
      postLog(_id, logActions.USER_ENDED_CALL);
      updateTelecAsync(_id, { status: ENDED });
      if (peer) {
          peer.destroy();
      }
      handleClose();
  }

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  }

  startCall = () => {
      const { peer, localStream } = this.state;
      const { selectedTelec } = this.props;
      const remoteUserId = selectedTelec.doctor._id;

      const calls = peer.call(remoteUserId, localStream);

      postLog(selectedTelec._id, logActions.PEER_CALL, { calls });

      calls.on('stream', (remoteStream) => {
          postLog(selectedTelec._id, logActions.PEER_CALL_STREAM);

          const videoReceiver = document.querySelector('#receiver');
          if (videoReceiver) {
              videoReceiver.srcObject = remoteStream;
          }
          postLog(selectedTelec._id, logActions.CALL_STARTED);
      });

      calls.on('error', (err) => {
          postLog(selectedTelec._id, logActions.PEER_CALL_ERROR, { ...err });
      });
  }

  openConnectionWithRemote = () => {
      const { selectedTelec } = this.props;
      const { peer } = this.state;
      const id = selectedTelec.doctor._id;
      const conn = peer.connect(id);

      conn.on('open', () => {
          postLog(selectedTelec._id, logActions.PEER_CONNECTION, { conn });
          this.startCall();
          this.setState({ conn });

          conn.on('data', (data) => {
              postLog(selectedTelec._id, logActions.PEER_CONNECTION_DATA, { data });
              this.pushMessage(data, id);
          });

          conn.on('error', (err) => {
              postLog(selectedTelec._id, logActions.PEER_CONNECTION_EROR, { ...err });
          });
      });
  }

  setUserStatus = async () => {
      const { selectedTelec } = this.props;
      const { peer } = this.state;
      try {
          await getUserStatus(selectedTelec.doctor._id, (snapshot) => {
              if (snapshot) {
                  this.setState({ userStatus: snapshot.data().status });
                  if (snapshot.data().status === 'connected') {
                      postLog(selectedTelec._id, logActions.REMOTE_USER_STATUS, snapshot.data().status);
                      if (peer) {
                          this.openConnectionWithRemote();
                      }
                  } else {
                      postLog(selectedTelec._id, logActions.REMOTE_USER_STATUS, snapshot.data().status);
                  }
              }
          });
      } catch (err) {
          console.log('error occureed on listening to userStatus', err);
      }
  }


  pushMessage = (message, sender) => {
      const { messages } = this.state;
      const { user } = this.props;
      const newMessages = [...messages];
      newMessages.push({
          sender,
          content: message,
          time: Date.now(),
      });
      setTimeout(() => {
          this.scrollToBottom();
      }, 500);
      if (user._id === sender) {
          this.setState({ messages: newMessages, msgToSend: '' });
      } else {
          this.setState({ messages: newMessages });
      }
  }

  scrollToBottom = () => {
      const element = document.getElementById('teleconv');
      if (element) {
          element.scrollTop = element.scrollHeight;
      }
  }

  handleSendMessage = (e) => {
      e.preventDefault();
      const { msgToSend, conn } = this.state;
      const { user } = this.props;
      if (conn && msgToSend.trim().length > 0) {
          conn.send(msgToSend);
          this.pushMessage(msgToSend, user._id);
      }
  }

  setLocalStream = (localStream) => {
      this.setState({ localStream, openCheckMediaDialog: false });
  }

  handleDisable = (option) => {
      const { localStream } = this.state;
      if (option === 'mic') {
          const { mic } = this.state;
          console.log({ localStream });
          localStream.getAudioTracks()[0].enabled = !localStream.getAudioTracks()[0].enabled;
          this.setState({ mic: !mic });
      } else {
          const { cam } = this.state;
          localStream.getVideoTracks()[0].enabled = !localStream.getVideoTracks()[0].enabled;

          this.setState({ cam: !cam });
      }
  }

  render() {
      const { open, handleClose, selectedTelec } = this.props;
      const {
          userStatus, messages, msgToSend,
          localStream, openCheckMediaDialog,
          mic, cam,
      } = this.state;
      let doctorFullName = '';
      if (selectedTelec) {
          doctorFullName = `${selectedTelec.doctor.lastName} ${selectedTelec.doctor.firstName}`;
      }

      const isRemoteConnected = userStatus === 'connected';
      return (
          <div className="rainbow-m-around_large">
              {localStream ? (
                  <Modal
                      isOpen={open}
                      onRequestClose={handleClose}
                      hideCloseButton
                      className="telec-start-modal"
                  >
                      <div className="telec-content-container">
                          <div className="call-container">
                              {isRemoteConnected ? (
                                  <div style={{ position: 'relative' }}>
                                      <video id="receiver" autoPlay className="receiver" />
                                      <div className="telec-icons-container">
                                          <i
                                              onClick={() => {
                                                  this.handleDisable('mic');
                                              }}
                                              className={mic ? 'fas fa-microphone' : 'fas fa-microphone-slash'}
                                              style={{ cursor: 'pointer', marginRight: '1rem', color: '#01b6f5' }}
                                          />
                                          <i
                                              onClick={() => {
                                                  this.handleDisable('cam');
                                              }}
                                              className={cam ? 'fas fa-video' : 'fas fa-video-slash'}
                                              style={{ cursor: 'pointer', color: '#01b6f5' }}
                                          />
                                          <i className="fal fa-phone-slash"
                                              onClick={() => {
                                                  this.endCall();
                                              }}
                                              style={{ cursor: 'pointer', color: '#ea4243' }}

                                          />
                                      </div>
                                  </div>
                              ) : (
                                  <div style={{ position: 'relative' }}>
                                      <h3 className="non-connected-remote"> Votre interlocuteur n'est pas encore connecté, vous serez notifé dés qu'il se connecte</h3>
                                      <div className="telec-icons-container">
                                          <i
                                              onClick={() => {
                                                  this.handleDisable('mic');
                                              }}
                                              className={mic ? 'fal fa-microphone' : 'fal fa-microphone-slash'}
                                              style={{ cursor: 'pointer', marginRight: '1rem', color: '#01b6f5' }}
                                          />
                                          <i
                                              onClick={() => {
                                                  this.handleDisable('cam');
                                              }}
                                              className={cam ? 'fal fa-video' : 'fal fa-video-slash'}
                                              style={{ cursor: 'pointer', marginRight: '1rem', color: '#01b6f5' }}
                                          />
                                          <i className="fal fa-phone-slash"
                                              onClick={() => {
                                                  this.endCall();
                                              }}
                                              style={{ cursor: 'pointer', color: '#ea4243' }}

                                          />
                                      </div>
                                  </div>
                              )}
                              <video id="caller" autoPlay className="caller" />

                          </div>
                          <div className="chat-container">
                              <div style={{ flex: 1, maxHeight: '80%' }}>
                                  <SelectedContact
                                      selectedUser={selectedTelec.doctor}
                                      style={{ position: 'relative', left: '1.25rem' }}
                                  />
                                  <div className="messages-cont" id="teleconv">
                                      <ChatMessages messages={messages} />
                                  </div>
                              </div>
                              <div className="react-rainbow-admin-messages_input-container">
                                  <form
                                      onSubmit={(e) => {
                                          this.handleSendMessage(e);
                                      }}
                                  >
                                      <Input
                                          className="react-rainbow-admin-messages_input"
                                          label="Tapez votre message"
                                          hideLabel
                                          name="msgToSend"
                                          value={msgToSend}
                                          onChange={this.handleChange}
                                          placeholder="Tapez votre message"
                                          iconPosition="right"
                                      />
                                      <Button
                                          type="submit"
                                          style={{ marginTop: '1rem', width: '100%' }}
                                          label="Envoyer"
                                          variant="brand"
                                          className="variant-brand"
                                          disabled={!isRemoteConnected}
                                      />
                                  </form>
                              </div>
                          </div>
                      </div>

                  </Modal>
              ) : (
                  <CheckMedia
                      selectedTelecId={selectedTelec._id}
                      doctorFullName={doctorFullName}
                      setLocalStream={(localstream) => { this.setLocalStream(localstream); }}
                      handleClose={() => { handleClose(); }}
                      open={openCheckMediaDialog} />
              ) }
          </div>
      );
  }
}

StartContainer.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    selectedTelec: PropTypes.object.isRequired,
    updateTelecAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});


const dispatchToProps = dispatch => bindActionCreators({
    updateTelecAsync,
}, dispatch);


export default connect(stateToProps, dispatchToProps)(StartContainer);
