import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeSnackbar } from '../../redux/actions/snackbar';
import './styles.css';

const Snackbar = (props) => {
    const {
        title, description, icon, open, closeSnackbar,
    } = props;
    const display = open ? 'block' : 'none';
    return (
        <div className="rainbow-p-bottom_x-small">
            <Notification
                className="notif"
                style={{ display }}
                title={title}
                description={description}
                icon={icon}
                onRequestClose={closeSnackbar}
            />
        </div>
    );
};


Snackbar.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    open: PropTypes.bool.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    description: PropTypes.string,
};

Snackbar.defaultProps = {
    description: '',
    title: undefined,
    icon: undefined,
};

const stateToProps = state => state.snackbar;

const dispatchToProps = dispatch => bindActionCreators(
    {
        closeSnackbar,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(Snackbar);
