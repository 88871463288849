import React from 'react';
import BigCalendar from 'react-big-calendar';
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import localizer from 'react-big-calendar/lib/localizers/globalize';
import PropTypes from 'prop-types';
import globalize from 'globalize';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './style.css';
import 'globalize/lib/cultures/globalize.culture.fr';

const globalizeLocalizer = localizer(globalize);
// const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const ColoredDateCellWrapper = ({ children }) => React.cloneElement(React.Children.only(children), {
    style: {
    },
});

const Event = ({ event }) => (
    <span
        style={{
            backgroundColor: '#1de9b6',
            padding: 'inherit',
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 25,
            overflowWrap: 'break-word',
            display: 'flex',
        }}
    >
        <strong style={{ color: 'white' }}>{event.title}</strong>
        {event.desc && `:  ${event.desc}`}
    </span>
);

Event.propTypes = {
    event: PropTypes.object.isRequired,
};

const Calendar = props => (
    <BigCalendar
        culture="fr"
        resizable={false}
        defaultDate={new Date()}
        localizer={globalizeLocalizer}
        messages={{
            today: "Aujourd'hui",
            previous: 'Précédent',
            next: 'Suivant',
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            agenda: 'Liste',
            showMore: total => `+${total} événements`,
            allDay: 'toute la journée',
        }}
        components={{ event: Event, timeSlotWrapper: ColoredDateCellWrapper }}
        {...props}
    />
);

export default Calendar;
