/* eslint-disable no-plusplus */
import * as NotificationApi from '../../../api/notifications';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_UNSEEN_NOTIFICATIONS = 'SET_UNSEEN_NOTIFICATIONS';
export const SET_UNSEEN_MESSAGES = 'SET_UNSEEN_MESSAGES';

export const setNotifications = value => ({
    type: SET_NOTIFICATIONS,
    payload: value,
});

export const setUnseenNotifications = value => ({
    type: SET_UNSEEN_NOTIFICATIONS,
    payload: value,
});
export const setUnseenMessages = value => ({
    type: SET_UNSEEN_MESSAGES,
    payload: value,
});


export const deleteNotifications = messagesEntry => async () => {
    try {
        await (NotificationApi.removeMessagesNotifications(messagesEntry));
    } catch (err) {
        // nada
    }
};


// eslint-disable-next-line consistent-return
export const setNotificationsAsync = userId => (dispatch) => {
    try {
        const respo = NotificationApi.getNotifications(userId, (snapshot) => {
            const notifications = [];
            const unseenMessages = [];

            // eslint-disable-next-line array-callback-return
            snapshot.docs.map((doc) => {
                if (doc.data().type === 'message') {
                    unseenMessages.push(doc.id);
                } else {
                    notifications.push({ ...doc.data(), _id: doc.id });
                }
            });
            dispatch(setNotifications(notifications));
            dispatch(setUnseenMessages(unseenMessages));
        });
        return respo;
    } catch (err) {
        // TODO Nada
    }
};
