/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';

const textStyles = {
    textAlign: 'center',
    fontSize: 15,
    padding: '0 16px',
};

const SavingAlertModal = (props) => {
    const { open, handleClose, handleSubmit } = props;
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                id="modal-2"
                isOpen={open}
                onRequestClose={handleClose}
                title="Alerte"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_large variant-success"
                            label="Je souhaite enregistrer"
                            variant="success"
                            onClick={handleClose}
                        />
                        <Button onClick={handleSubmit} className="variant-brand" label="Je souhaite quitter" variant="brand" />
                    </div>
                )}
            >
                <p style={textStyles}>
                       Assurer vous d'enregistrer vos données avant de quitter
                </p>
            </Modal>
        </div>
    );
};

SavingAlertModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


export default (SavingAlertModal);
