/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table, Button,
} from 'react-rainbow-components';
import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import countryList from 'react-select-country-list';
import { bindActionCreators } from 'redux';
import TablePagination from '../../../components/TablePagination';
import PermissionsTableButtons from './permissionsTableButtons';
import DeletePermission from '../../../components/DeleteModal';
import { revokePermissionAsync, grantPermissionAsync } from '../../../redux/actions/auth';
import AddPermission from '../add';
import history from '../../../history';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

class PermissionsTable extends Component {
       state = {
           activePage: 1,
           tableData: [],
           accessToDelete: {
               label: '',
               email: '',
           },
           addFormData: {
               fullName: '',
               email: '',
               country: '',
           },
           openAddForm: false,
       }

       componentDidMount() {
           this.mapDataToState();
           if (history.location.state && history.location.state.addFromDashboard) {
               this.setState({ openAddForm: true });
           }
       }

       mapDataToState = () => {
           const { user } = this.props;
           const tableData = [];
           if (user) {
               user.acces.map(row => tableData.push({
                   fullName: row.fullName,
                   email: row.email,
                   country: countryList().getLabel(row.country) || '',
                   haveAcc: row.isUser ? 'Oui' : 'Non',
               }));
               this.setState({ tableData });
           }
       };


    getTableData = () => {
        const { activePage, tableData } = this.state;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;

        return tableData.slice(firstItem, lastItem);
    }

    getPages = () => {
        const { tableData } = this.state;
        return Math.ceil(tableData.length / 8);
    }


    handleOnChange = (event, page) => {
        this.setState({ activePage: page });
    }

    handleAddFormChange = (e) => {
        const { addFormData } = this.state;
        this.setState({ addFormData: { ...addFormData, [e.target.name]: e.target.value } });
    }

    handleSubmitAdd = () => {
        const { addFormData } = this.state;
        const { ...body } = addFormData;
        const { user } = this.props;
        const UserFullName = `${user.firstName} ${user.lastName}`;

        const { grantPermissionAsync } = this.props;
        grantPermissionAsync(body, UserFullName);
        this.handleOpenAddForm();
    }

    handleOpenAddForm = () => {
        const { openAddForm } = this.state;
        if (openAddForm) {
            this.setState({
                addFormData: {
                    fullName: '',
                    country: '',
                    email: '',
                },
            });
        }
        this.setState({ openAddForm: !openAddForm });
    }

    handleDeleteDialog = (motif) => {
        const { openDeleteDialog } = this.state;
        this.setState({
            openDeleteDialog: !openDeleteDialog,
            accessToDelete: motif || {
                fullName: '',
                email: '',
            },
        });
    }

    handleDelete = () => {
        const { user } = this.props;
        const { accessToDelete } = this.state;
        const senderFullName = `${user.firstName} ${user.lastName}`;
        const body = {
            email: accessToDelete.email,
            fullName: accessToDelete.fullName,
        };
        const { revokePermissionAsync } = this.props;
        revokePermissionAsync(body, senderFullName);
        this.handleDeleteDialog();
    }

    render() {
        const {
            activePage, openDeleteDialog,
            accessToDelete, openAddForm,
            addFormData,
        } = this.state;
        const { withAddOption } = this.props;
        return (
            <div>
                {withAddOption ? (

                    <Button variant="brand" className="add-purpose-button" onClick={this.handleOpenAddForm}>
                        <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
     Donner un nouvel accès
                    </Button>
                ) : null}
                <div className="react-rainbow-admin-users_tab-content">


                    <Table resizeColumnDisabled data={this.getTableData()} keyField="label" sortedBy="label" emptyDescription="Aucune donnée a afficher" emptyTitle="">
                        <Column header="Nom et Prénom" field="fullName" />
                        <Column header="Email" field="email" />
                        <Column header="Pays" field="country" />
                        <Column header="Utilisateur de katomi" field="haveAcc" />
                        {withAddOption ? (
                            <Column header="Actions" field="motifData" component={PermissionsTableButtons} handleDeleteDialog={this.handleDeleteDialog} />
                        ) : null}
                    </Table>
                    <TablePagination
                        pages={this.getPages()}
                        activePage={activePage}
                        onChange={this.handleOnChange} />
                    {withAddOption ? (
                        <div>
                            <AddPermission
                                open={openAddForm}
                                formData={addFormData}
                                handleClose={this.handleOpenAddForm}
                                handleChange={this.handleAddFormChange}
                                handleSubmit={this.handleSubmitAdd}
                            />
                            <DeletePermission
                                open={openDeleteDialog}
                                handleClose={this.handleDeleteDialog}
                                modalTitle="Supression d'un motif de consultation"
                                contentText={`êtes vous sûre de bien vouloir supprimer l'accés au medecin ${accessToDelete.fullName}`}
                                data={accessToDelete}
                                handleSubmit={this.handleDelete}
                            />
                        </div>
                    ) : null}


                </div>
            </div>
        );
    }
}

PermissionsTable.propTypes = {
    user: PropTypes.object.isRequired,
    revokePermissionAsync: PropTypes.func.isRequired,
    grantPermissionAsync: PropTypes.func.isRequired,
    withAddOption: PropTypes.bool,
};

PermissionsTable.defaultProps = {
    withAddOption: true,
};


const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators({
    revokePermissionAsync,
    grantPermissionAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(PermissionsTable);
