/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table,
} from 'react-rainbow-components';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import TablePagination from '../../../components/TablePagination';
import TableActions from './tableActions';
import TableTitle from '../../../components/TableTitle';
import ClaimModal from '../claimModal';

const formatDate = (date) => {
    const newDate = new Date(date).getTime();
    return DateTime.fromMillis(newDate).toFormat('dd/MM/yyyy à hh:mm');
};


const hrefStyles = {
    textDecoration: 'none',
    color: '#009acf',
};

const maxWidth = cond => (cond ? '80vw' : 'inherit');
// eslint-disable-next-line jsx-a11y/anchor-has-content
const InvoiceComponent = ({ row }) => (<a href={row.invoice} target="_blanc" style={hrefStyles}> Cliquez ici </a>);

InvoiceComponent.propTypes = {
    row: PropTypes.object.isRequired,
};

class TelecHistoryTable extends React.Component {
    state = {
        activePage: 1,
        openClaimDialog: false,
        selectedTelec: null,
    };

    handleClaimModal = (selectedTelec) => {
        const { openClaimDialog } = this.state;
        this.setState({ openClaimDialog: !openClaimDialog, selectedTelec });
    }


     prepareTableData = () => {
         const { telecList } = this.props;
         const tableData = [];
         telecList.map(telec => tableData.push({
             doctor: `${telec.doctor.lastName} ${telec.doctor.firstName}`,
             date: formatDate(telec.date),
             status: telec.status,
             invoice: telec.invoice || '',
             _id: telec._id,
             telec,
         }));
         return tableData;
     };

    setActivePage = (ev, activePage) => {
        this.setState({ activePage });
    }


     getTableData = () => {
         const { activePage } = this.state;
         const firstItem = (activePage - 1) * 8;
         const lastItem = firstItem + 8;
         return this.prepareTableData().slice(firstItem, lastItem);
     };

     getPages = () => {
         const { telecList } = this.props;
         Math.ceil(telecList.length / 8);
     };

     render() {
         const { activePage, openClaimDialog } = this.state;
         const { handleDelete, showTitle } = this.props;
         return (
             <div>
                 <div style={{ maxWidth: maxWidth(showTitle) }} className="react-rainbow-admin-users_tab-content">
                     {showTitle ? (
                         <TableTitle title="Historique des téléconsultations" />

                     ) : null}

                     <Table resizeColumnDisabled data={this.getTableData()} keyField="nature" emptyDescription="Aucune téléconsultation effectuée" emptyTitle="">
                         <Column header="Date" field="date" />
                         <Column header="Médecin" field="doctor" />
                         <Column header="Status" field="status" />
                         <Column header="Facture" field="invoice" component={InvoiceComponent} />
                         {showTitle ? (
                             <Column header="Actions"
                                 field="_id"
                                 component={TableActions}
                                 handleReclamation={this.handleClaimModal}
                                 handleDeleteDialog={handleDelete}
                             />
                         ) : null}

                     </Table>
                     <TablePagination
                         pages={this.getPages()}
                         activePage={activePage}
                         onChange={this.setActivePage} />

                 </div>
                 {openClaimDialog ? (
                     <ClaimModal
                         open={openClaimDialog}
                         handleClose={this.handleClaimModal} />
                 ) : null }
             </div>
         );
     }
}
// }


TelecHistoryTable.propTypes = {
    telecList: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    showTitle: PropTypes.bool,
};

TelecHistoryTable.defaultProps = {
    showTitle: true,
};

const stateToProps = state => ({
    telecList: state.teleconsultation.old,
});

export default connect(stateToProps)(TelecHistoryTable);
