/* eslint-disable no-loop-func */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Column, Table,
} from 'react-rainbow-components';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import TablePagination from '../../../components/TablePagination';
import TableActions from './tableActions';
import { updateTelecAsync, payPlanedTelecAsync } from '../../../redux/actions/teleconsultation';
import { setSnackbar } from '../../../redux/actions/snackbar';
import RespondToTelec from '../RespondDialog';
import { PAYMENT_PENDING } from '../../../config';
import PaymentModal from '../add/payment';
import TableTitle from '../../../components/TableTitle';
import CancelModal from '../cancelModal';

// TEST ENV
const stripePromise = loadStripe('pk_test_qgkr0EKqf0vIEmZ99vuizvcz00JpB55I46');

// TODO REFACTOR THIS
const formatDate = (date) => {
    const newDate = new Date(date).getTime();
    return DateTime.fromMillis(newDate).toFormat('dd/MM/yyyy');
};

class PlanificationTable extends Component {
       state = {
           activePage: 1,
           openRespondModal: false,
           openPaymentModal: false,
           openCancelModal: false,
           selectedTelec: null,
       }

    prepareTableData = () => {
        const { telecList } = this.props;
        const result = [];
        if (telecList) {
            telecList.map(telec => result.push({
                fullName: `${telec.doctor.lastName} ${telec.doctor.firstName}`,
                date: formatDate(telec.date),
                unparsedDate: telec.date,
                status: telec.status,
                _id: telec._id,
                doctor: telec.doctor._id,
                patient: telec.patient._id,
                patientFullName: `${telec.patient.lastName} ${telec.patient.firstName}`,
                telec,
            }));
        }
        return result;
    }

    getTableData = () => {
        const { activePage } = this.state;
        const firstItem = (activePage - 1) * 8;
        const lastItem = firstItem + 8;

        return this.prepareTableData().slice(firstItem, lastItem);
    }

    getPages = () => {
        const { telecList } = this.props;
        return Math.ceil(telecList.length / 8);
    }


    handleOnChange = (event, page) => {
        this.setState({ activePage: page });
    }

    handleRespondModal = (telec) => {
        const { openRespondModal } = this.state;
        this.setState({
            openRespondModal: !openRespondModal,
            selectedTelec: telec,
        });
    }

    handleRespond = (selectedOption) => {
        const { selectedTelec } = this.state;
        const { setSnackbar, updateTelecAsync } = this.props;
        if (!selectedOption) {
            setSnackbar('Veuillez choisir une option', 'error');
        } else {
            const body = {
                status: selectedOption,
                doctor: selectedTelec.doctor,
                patientFullName: selectedTelec.patientFullName,
            };
            updateTelecAsync(selectedTelec._id, body);
            this.handleRespondModal();
            if (selectedOption === PAYMENT_PENDING) {
                this.handlePaymentModal();
            }
        }
    }

    handlePaymentModal = (telec) => {
        const { openPaymentModal } = this.state;
        this.setState({ openPaymentModal: !openPaymentModal, selectedTelec: telec });
    }

    submitPayment = async (token) => {
        const { payPlanedTelecAsync } = this.props;
        const { selectedTelec } = this.state;
        const body = {
            telec: { ...selectedTelec },
            token,
        };
        body.telec.date = body.telec.unparsedDate;
        const res = await payPlanedTelecAsync(body);
        if (res) {
            this.handlePaymentModal();
        }
    }

    handleCancelModal = (telec) => {
        const { openCancelModal } = this.state;
        this.setState({ openCancelModal: !openCancelModal, selectedTelec: telec });
    }


    render() {
        const {
            activePage, openRespondModal, openPaymentModal, openCancelModal,
        } = this.state;
        const { handleDelete } = this.props;
        return (
            <div className="react-rainbow-admin-users_tab-content">
                <TableTitle title="Prochaines Téléconsultations" />
                <Table resizeColumnDisabled data={this.getTableData()} keyField="_id" sortedBy="date" emptyDescription="Aucune téléconsultation a afficher" emptyTitle="">
                    <Column header="Médecin" field="fullName" />
                    <Column header="Date" field="date" />
                    <Column header="Status" field="status" />
                    <Column
                        header="Actions"
                        field="_id"
                        component={TableActions}
                        handlePayment={this.handlePaymentModal}
                        handleStartDialog={this.handleStartModal}
                        handleRespondDialog={this.handleRespondModal}
                        handleDeleteDialog={handleDelete}
                        handleCancel={this.handleCancelModal} />
                </Table>

                <TablePagination
                    pages={this.getPages()}
                    activePage={activePage}
                    onChange={this.handleOnChange} />

                {openRespondModal ? (
                    <RespondToTelec
                        handleSubmit={this.handleRespond}
                        open={openRespondModal}
                        handleClose={this.handleRespondModal}
                    />
                ) : null}
                {openPaymentModal ? (
                    <Elements stripe={stripePromise}>
                        <PaymentModal
                            open={openPaymentModal}
                            handleSubmit={this.submitPayment}
                            handleClose={this.handlePaymentModal}
                        />
                    </Elements>
                ) : null}

                {openCancelModal ? (
                    <CancelModal
                        open={openCancelModal}
                        handleClose={this.handleCancelModal}
                    />
                ) : null}
            </div>
        );
    }
}

PlanificationTable.propTypes = {
    telecList: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    updateTelecAsync: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    payPlanedTelecAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    telecList: state.teleconsultation.incoming,
});

const dispatchToProps = dispatch => bindActionCreators({
    updateTelecAsync,
    setSnackbar,
    payPlanedTelecAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(PlanificationTable);
