/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import {
    Button,
} from 'react-rainbow-components';

import './styles.css';

// TODO MOVE THIS FILE TO /COMPONENTS BECAUSE NOW ITS GENERIC TO TWO OR MORE MOTHER COMPONETS
const TableActionDelete = (props) => {
    const { row, handleDeleteDialog } = props;
    return (
        <div className="actions-container">
            <Button label="Supprimer" variant="destructive" className="button-action variant-destructive" onClick={() => { handleDeleteDialog(row); }} />
        </div>


    );
};

TableActionDelete.propTypes = {
    row: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
};


export default (TableActionDelete);
