import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Input, RadioGroup, DatePicker, Button, Select,
} from 'react-rainbow-components/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSnackbar } from '../../redux/actions/snackbar';
import './styles.css';


const AddAntecedent = (props) => {
    const {
        handleChange,
        accordionLabel,
        showTreatment,
        options,
        nature,
        handleAdd, handleRadioChange,
        dataToAdd,
        medicalSelectOptions,
        allergiesSelectOptions,
        globalTab,
    } = props;


    const selectedOption = dataToAdd[nature].radioValue;
    let dateValue = new Date();
    if (selectedOption) {
        if (dataToAdd[nature][selectedOption].date) {
            dateValue = dataToAdd[nature][selectedOption].date;
        }
    }

    let dateLabel = 'Date';
    const [showForm, setShowForm] = useState(false);


    const handleClick = () => {
        if (selectedOption) {
            if (handleAdd(nature)) {
                setShowForm(!showForm);
            }
        } else {
            const { setSnackbar } = props;
            setSnackbar("Veuillez remplir le formulaire avant d'ajouter un antécédent", 'error');
        }
    };

    switch (globalTab) {
        case 'medical-history':
            dateLabel = 'Date de début de la maladie'; break;
        case 'surgical-history':
            dateLabel = "Date de l'intervention"; break;
        case 'treatments':
            dateLabel = 'Date de début du traitement'; break;
        default: dateLabel = 'Date'; break;
    }
    if (selectedOption === 'allergies') {
        dateLabel = "Date de debut de l'allergie";
    }
    return (
        <div className="accordion-container">

            {!showForm ? (
                <Button style={{ marginBottom: '1em' }} variant="base" label={accordionLabel} onClick={() => { setShowForm(!showForm); }} />

            ) : null}
            {showForm ? (
                <div>
                    <RadioGroup
                        options={options}
                        value={selectedOption}
                        onChange={(e) => { handleRadioChange(e, nature); }}
                    />
                    <div className="rainbow-flex_column rainbow-flex">
                        {selectedOption === 'medical' ? (
                            <Select
                                style={{ margin: '1rem 0px' }}
                                label="Type d'antécédent"
                                options={medicalSelectOptions}
                                name="type"
                                className="rainbow-p-horizontal_medium"
                                onChange={e => handleChange(e, nature)}
                            />
                        ) : (null)}

                        {selectedOption === 'allergies' ? (
                            <Select
                                label="Type d'allergie"
                                style={{ margin: '1rem 0px' }}
                                options={allergiesSelectOptions}
                                name="type"
                                className="rainbow-p-horizontal_medium"
                                onChange={e => handleChange(e, nature)}
                            />
                        ) : (null)}

                        <div className="rainbow-flex rainbow-justify rainbow-flex_wrap">

                            {showTreatment ? (
                                <Input
                                    onChange={(e) => { handleChange(e, nature); }}
                                    style={{ margin: '1rem auto' }}
                                    className="rainbow-p-horizontal_medium flex-grow"
                                    component={Input}
                                    name="traitement"
                                    label="Traitement"
                                    disabled={selectedOption === null}
                                    type="text" />
                            ) : null}


                            <DatePicker
                                locale="fr"
                                className="rainbow-p-horizontal_medium"
                                style={{ margin: '1rem auto' }}
                                value={dateValue}
                                label={dateLabel}
                                disabled={selectedOption === null}
                                name="date"
                                maxDate={new Date()}
                                onChange={(value) => {
                                    handleChange({

                                        target: {
                                            name: 'date',
                                            value: value.toString(),
                                        },

                                    }, nature);
                                }}
                            />
                            <Input
                                onChange={(e) => { handleChange(e, nature); }}
                                className="rainbow-p-horizontal_medium flex-grow"
                                component={Input}
                                style={{ margin: '1rem auto' }}
                                name="observation"
                                label="Observations"
                                disabled={selectedOption === null}
                                type="text" />
                        </div>

                    </div>
                    <div className="rainbow-flex rainbow-justify rainbow-p-horizontal_small">

                        <Button className="button-add variant-success" label="Ajouter" variant="success" onClick={() => { handleClick(); }} />
                    </div>
                </div>

            ) : null}


        </div>
    );
};

AddAntecedent.propTypes = {
    handleChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    dataToAdd: PropTypes.object.isRequired,
    accordionLabel: PropTypes.string.isRequired,
    showTreatment: PropTypes.bool,
    handleAdd: PropTypes.func.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    nature: PropTypes.string.isRequired,
    medicalSelectOptions: PropTypes.func,
    allergiesSelectOptions: PropTypes.func,
    globalTab: PropTypes.string.isRequired,
    setSnackbar: PropTypes.func.isRequired,
};


AddAntecedent.defaultProps = {
    handleChange: () => null,
    showTreatment: true,
    medicalSelectOptions: [],
    allergiesSelectOptions: [],
};


const dispatchToProps = dispatch => bindActionCreators(
    {
        setSnackbar,
    },
    dispatch,
);

export default connect(null, dispatchToProps)(AddAntecedent);
