import { SET_APP_LOADING, SET_CONTENT_LOADING, SET_DIALOG_LOADING } from '../../actions/loading';

const initialState = {
    appIsLoading: false,
    contentIsLoading: false,
    dialogIsLoading: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_LOADING:
            return { appIsLoading: action.payload };
        case SET_CONTENT_LOADING:
            return { contentIsLoading: action.payload };
        case SET_DIALOG_LOADING:
            return { dialogIsLoading: action.payload };
        default:
            return state;
    }
};

export default auth;
