import React from 'react';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import PermissionsTable from './permissionsTable';
import { navigateTo } from '../../history';
import './styles.css';

const Permissions = () => (
    <div className="patients-container">
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs className="is-jura" style={{ marginBottom: '3rem' }}>
                <Breadcrumb label="Médecins autorisés a consulter/modifier votre dossier médical" onClick={() => navigateTo('/c-purposes')} />
            </Breadcrumbs>
        </div>
        <PermissionsTable />
    </div>
);

export default (Permissions);
