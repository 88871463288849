import React from 'react';
import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';
import Identity from '../../components/PatientGeneralInfo/identity';
import AddressAndContact from '../../components/PatientGeneralInfo/address';
import GeneralForm from '../../components/PatientGeneralInfo/general';

import './styles.css';


const switchTab = (tab, handleChange, info) => {
    switch (tab) {
        case 'identity': return <Identity info={info} handleChange={handleChange} />;
        case 'address': return <AddressAndContact info={info} handleChange={handleChange} />;
        case 'general': return <GeneralForm handleChange={handleChange} info={info} />;
        default: return <h1>default</h1>;
    }
};


const GeneralInfo = (props) => {
    const {
        currentTab, handleOnSelect, handleChange, info,
    } = props;
    return (
        <div>
            <Tabset
                id="tabset-2"
                onSelect={(ev, tab) => handleOnSelect(tab, 'general')}
                activeTabName={currentTab}
                className="rainbow-background-color_gray-1"
            >
                <Tab
                    label="Identité"
                    name="identity"
                    id="identity"
                    ariaControls="identityTab"
                />
                <Tab
                    label="Adresse et Contact"
                    name="address"
                    id="address"
                    ariaControls="addressTab"
                />
                <Tab label="Données Générales" name="general" id="general" ariaControls="generalTab" />
            </Tabset>
            <div>

                {switchTab(currentTab, handleChange, info)}
            </div>
        </div>
    );
};

GeneralInfo.propTypes = {
    currentTab: PropTypes.string.isRequired,
    handleOnSelect: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
};

export default GeneralInfo;
