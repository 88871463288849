/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import * as AuthAPI from '../../../api/auth';
import { auth, db } from '../../../firebase';
import { setSnackbar } from '../snackbar';
import { errorCodes } from '../../../config';
import { setAppIsLoading, setContentIsLoading } from '../loading';
import { createNotification } from '../../../api/notifications';

export const SET_USER = 'SET_USER';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';

export const updatePermissions = value => ({
    type: UPDATE_PERMISSIONS,
    payload: value,
});

export const setUser = value => ({
    type: SET_USER,
    payload: value,
});


export const sessionExpired = (dispatch, err) => {
    const errMsg = errorCodes[err.code];
    if (errMsg) {
        dispatch(setSnackbar(errMsg, 'error'));
        // TODO DISCONNECT THIS MOFFO
        return true;
    }
    return false;
};


export const checkAuthAsync = () => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const authStatus = await AuthAPI.getStatus();
        if (authStatus.data) dispatch(setUser(authStatus.data));
    } catch (err) {
        if (sessionExpired(dispatch, err)) {
            dispatch(setUser(null));
        }
    }
    dispatch(setAppIsLoading(false));
};

export const loginAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await auth.signInWithEmailAndPassword(
            credentials.email,
            credentials.password,
        );

        if (res.user) {
            const idToken = await res.user.getIdToken();
            const id = res.user.uid;
            const response = await AuthAPI.login({ idToken, id });
            const AuthenticatedUser = response.data;
            dispatch(setUser(AuthenticatedUser));
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
    dispatch(setAppIsLoading(false));
};

export const updateAsync = (body, imagesFiles, id, hideSnackbar) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AuthAPI.update(body, imagesFiles, id);
        if (res.data) {
            dispatch(setUser(res.data));
            if (!hideSnackbar) {
                dispatch(
                    setSnackbar('Modifications enregistrées avec succès !', 'success'),
                );
            }
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
    dispatch(setContentIsLoading(false));
};

export const disconnectAsync = () => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        await AuthAPI.logout();
        dispatch(setUser(null));
    } catch (err) {
        // nvm
    }
    dispatch(setAppIsLoading(false));
};

export const grantPermissionAsync = (body, userFullName) => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await AuthAPI.grantPermission(body);
        if (res.data) {
            dispatch(updatePermissions(res.data.access));
            dispatch(setSnackbar('Autorisation accordée avec succès !', 'success'));
            if (res.data.relatedDoctor) {
                await createNotification({
                    type: 'patients',
                    date: Date.now(),
                    user: res.data.relatedDoctor._id,
                    user_info: {
                        firstName: res.data.relatedDoctor.firstName,
                        lastName: res.data.relatedDoctor.lastName,
                    },
                    content: `${userFullName} vous à accordé l'autorisation de consulter et modifier son dossier médicale`,
                });
            }
        }
    } catch (err) {
        sessionExpired(dispatch, err);
    }
    dispatch(setAppIsLoading(false));
};

export const revokePermissionAsync = (body, userFullName) => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await AuthAPI.revokePermission(body);
        if (res.data) {
            dispatch(updatePermissions(res.data.access));
            dispatch(setSnackbar('Autorisation retirée avec succès !', 'success'));
            if (res.data.relatedDoctor) {
                await createNotification({
                    type: 'patients',
                    date: Date.now(),
                    user: res.data.relatedDoctor._id,
                    user_info: {
                        firstName: res.data.relatedDoctor.firstName,
                        lastName: res.data.relatedDoctor.lastName,
                    },
                    content: `${userFullName} vous à supprimé l'autorisation de consulter et modifier son dossier médicale`,
                });
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar('Une erreur est survenue veuillez réessayer plus tard', 'error'));
        }
    }
    dispatch(setAppIsLoading(false));
};

export const signUpAsync = credentials => async (dispatch) => {
    dispatch(setAppIsLoading(true));
    try {
        const res = await auth.createUserWithEmailAndPassword(
            credentials.email,
            credentials.password,
        );
        if (res.user) {
            const token = await res.user.getIdToken();
            res.user.sendEmailVerification();
            await db.collection('teleconsultations').add({ [res.user.uid]: 'disconnected' });
            credentials._id = res.user.uid;
            credentials.token = token;
            const response = await AuthAPI.register(credentials);
            if (response.data) {
                await db.collection('teleconsultations').add({ [response.data._id]: 'disconnected' });
                dispatch(setUser(response.data));
            }
        }
    } catch (err) {
        if (!sessionExpired(dispatch, err)) {
            dispatch(setSnackbar('Une erreur est survenue veuillez réessayer plus tard', 'error'));
        }
    }
    dispatch(setAppIsLoading(false));
};
