/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import { STARTED, PAYED, ENDED } from '../../../config';

const TelecHistoryTableActions = (props) => {
    const { row, handleDeleteDialog, handleReclamation } = props;
    // eslint-disable-next-line max-len
    const enableReclamations = row.status === PAYED || row.status === STARTED || row.status === ENDED;

    return (
        <div className="rainbow-flex rainbow-flex_wrap">
            {enableReclamations ? (
                <Button
                    label="Réclamer un remboursement"
                    variant="brand"
                    className="button-action variant-brand"
                    onClick={() => {
                        handleReclamation(row);
                    }}
                />
            ) : null}

            <Button
                label="Supprimer"
                variant="destructive"
                className="button-action variant-destructive"
                onClick={() => {
                    handleDeleteDialog(row);
                }}
            />
        </div>
    );
};

TelecHistoryTableActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
    handleReclamation: PropTypes.func.isRequired,
};

export default TelecHistoryTableActions;
