import axios from 'axios';
import { API_CHAT, API_AUTH } from '../config';


const getDoctors = async (body) => {
    const res = await axios.post(`${API_AUTH}/list`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getConversationById = async (id) => {
    const res = await axios.get(`${API_CHAT}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};


const getNumberOfUnseenMessages = async () => {
    const res = await axios.get(`${API_CHAT}/seen`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const setSeenOnConversationById = async (id) => {
    const res = await axios.put(`${API_CHAT}/${id}`, null, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getConversationByUsersIds = async (body) => {
    const res = await axios.post(`${API_CHAT}/`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const postMessage = async (body) => {
    const res = await axios.post(`${API_CHAT}/msg`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const getAllConversations = async () => {
    const res = await axios.get(`${API_CHAT}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

export {
    getDoctors,
    getConversationById,
    getConversationByUsersIds,
    postMessage,
    getAllConversations,
    setSeenOnConversationById,
    getNumberOfUnseenMessages,
};
