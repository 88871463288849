import { DateTime } from 'luxon';
import { sessionExpired } from '../auth';
import { getTeleconsultations } from '../../../api/teleconsultation';
import { setTeleconsultationList } from '../teleconsultation';

export const START_LOADING_DASHBOARD = 'START_LOADING_DASHBOARD';
export const STOP_LOADING_DASHBOARD = 'STOP_LOADING_DASHBOARD';
export const LOAD_DASHBOARD_DATA = 'LOAD_DASHBOARD_DATA';

export const loadDashboardData = value => ({
    type: LOAD_DASHBOARD_DATA,
    payload: value,
});

export const startLoadDashboardData = () => ({
    type: LOAD_DASHBOARD_DATA,
    payload: true,
});

export const stopLoadDashboardData = () => ({
    type: LOAD_DASHBOARD_DATA,
    payload: false,
});


export const fetchDashboardData = () => async (dispatch) => {
    dispatch(startLoadDashboardData());
    try {
        const res = await getTeleconsultations();

        const body = {
            subscriptionType: "pas d'abonnement",
            subscriptionExpires: "pas d'abonnement",
        };

        if (res.data) {
            dispatch(setTeleconsultationList(res.data));
            const formatDate = (date) => {
                const newJSdate = new Date(date).getTime();
                return DateTime.fromMillis(newJSdate).toLocaleString(DateTime.DATETIME_MED);
            };

            if (res.data.startingToday.length > 0) {
                body.nextTelec = formatDate(res.data.startingToday[0].date);
            } else if (res.data.incoming.length > 0) {
                body.nextTelec = formatDate(res.data.incoming[0].date);
            } else {
                body.nextTelec = 'pas de téléconsultations';
            }

            if (res.data.old.length > 0) {
                body.lastTelec = formatDate(res.data.old[0].date);
            } else {
                body.lastTelec = 'pas de téléconsultations';
            }
        }

        dispatch(loadDashboardData(body));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(stopLoadDashboardData());
};
