/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Input, Button, Modal, Select,
} from 'react-rainbow-components';
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Email, User } from '../../../components/icons';
import { selectOptions } from '../../../config';
import './styles.css';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

const AddCPurpose = (props) => {
    const {
        open, handleChange, handleSubmit, handleClose, formData,
    } = props;
    const { fullName, country, email } = formData;
    const COUNTRY_OPTIONS = selectOptions.allCountriesList;
    return (
        <Modal
            isOpen={open}
            size="medium"
            onRequestClose={handleClose}
            title="Ajout d'une permission d'accès"
            footer={(
                <div className="rainbow-flex  rainbow-p-top_large submit-buttons-cpurpose">

                    <Button variant="destructive" className="add-index-c-button variant-destructive" onClick={() => { handleClose(); }}>
                          Annuler
                        <FontAwesomeIcon icon={faTimesCircle} className="rainbow-m-left_small" />
                    </Button>
                    <Button variant="success" className="add-index-c-button variant-success" onClick={() => { handleSubmit(); }}>
                          Enregistrer
                        <FontAwesomeIcon icon={faSave} className="rainbow-m-left_small" />
                    </Button>
                </div>
            )}
        >
            <form>
                <Input
                    onChange={(e) => { handleChange(e); }}
                    className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                    icon={<User />}
                    value={fullName}
                    name="fullName"
                    label="Nom et prénom du médecin"
                    required
                    type="text" />
                <Input
                    onChange={(e) => { handleChange(e); }}
                    className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                    icon={<Email />}
                    value={email}
                    name="email"
                    label="Email du médecin"
                    type="text" />
                <Select
                    onChange={(e) => { handleChange(e); }}
                    className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                    value={country}
                    name="country"
                    options={COUNTRY_OPTIONS}
                    label="Pays du médecin"
                    type="text" />
            </form>


        </Modal>

    );
};


AddCPurpose.propTypes = {
    open: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
};

AddCPurpose.defaultProps = {
};

export default AddCPurpose;
