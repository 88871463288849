import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Application from 'react-rainbow-components/components/Application';
import Sidebar from 'react-rainbow-components/components/Sidebar';
import SidebarItem from 'react-rainbow-components/components/SidebarItem';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-rainbow-components/components/Spinner';
import Routes from '../routes';
import SectionHeading from '../components/SectionHeading';
import {
    DashboardIcon,
    MessageIcon,
    VideoChat,
    Settings,
    MedicalRecord,
} from '../components/icons';
import SavingAlertModal from '../components/SavingAlertModal';
import history from '../history';
import toogleSidebar, { navigate } from '../redux/actions/app';
import { checkAuthAsync } from '../redux/actions/auth';
import Snackbar from '../components/Snackbar';
import InternetDetector from '../components/InternetDetector';
import './styles.css';

const resolveCurrentUrl = () => history.location.pathname.split('/')[1] || '';

const isLoginRoute = () => resolveCurrentUrl() === 'login';
const isSignupRoute = () => resolveCurrentUrl() === 'signup';
const isUnconfirmedRoute = () => resolveCurrentUrl() === 'unconfirmed';

class App extends Component {
  state = {
      selectedItem: resolveCurrentUrl(),
      openSavingAlert: false,
      nextRoute: null,
      oldLocation: null,
  };

  componentDidMount() {
      const { checkAuthAsync, user } = this.props;
      checkAuthAsync();

      if (!isLoginRoute() && !isSignupRoute() && !user) {
          const oldLocation = history.location.pathname;
          if (oldLocation) {
              this.setState({ oldLocation });
          }
          history.replace('/login');
      }
      history.listen((location) => {
          if (location.pathname.length > 1) {
              this.setState({
                  selectedItem: resolveCurrentUrl(),
                  oldLocation: location.pathname,
              });
          } else {
              this.setState({ selectedItem: location.pathname });
          }
      });
  }

  componentDidUpdate(props) {
      const { isSidebarHidden, user } = this.props;
      if (props.user !== user) {
          this.authCheck();
      }
      const isSidebarOpen = !isSidebarHidden;
      document.body.style.overflow = 'auto';
      if (isSidebarOpen) {
          document.body.style.overflow = 'hidden';
      }
  }

  authCheck = () => {
      const { user } = this.props;
      const { oldLocation } = this.state;

      if (user) {
          if (!user.email_verified) {
              history.replace('/unconfirmed');
              this.forceUpdate();
          } else if (oldLocation !== null) {
              if (oldLocation === '/login' || oldLocation === '/signup' || oldLocation === '/unconfirmed') {
                  history.replace('/');
              } else {
                  history.replace(oldLocation);
              }
              this.forceUpdate();
          } else {
              history.replace('/');
              this.forceUpdate();
          }
      } else {
          history.replace('/login');
      }
  };

  getSidebarClassNames = () => {
      const { isSidebarHidden } = this.props;
      return classnames('react-rainbow-admin-app_sidebar-container', {
          'react-rainbow-admin-app_sidebar-container--sidebar-hidden': isSidebarHidden,
      });
  };

  handleOnSelect = (e, selectedItem) => {
      this.handleSavingAlert(selectedItem);
  };

  handleSavingAlert = (nextRoute) => {
      const { openSavingAlert } = this.state;
      if (resolveCurrentUrl() === 'medical-record') {
          this.setState({ openSavingAlert: !openSavingAlert, nextRoute });
      } else {
          if (openSavingAlert) {
              this.setState({ openSavingAlert: !openSavingAlert });
          }

          if (nextRoute) {
              if (nextRoute.length > 1) {
                  history.push(`/${nextRoute}`);
              } else {
                  history.push(nextRoute);
              }
          }
      }
  };

  goToNextRoute = () => {
      const { nextRoute } = this.state;

      if (nextRoute.length > 1) {
          history.push(`/${nextRoute}`);
      } else {
          history.push(nextRoute);
      }
      this.handleSavingAlert();
  };

  render() {
      const { selectedItem, openSavingAlert } = this.state;
      const {
          toogleSidebar,
          isSidebarHidden,
          appIsLoading,
          snackbar,
          user,
      } = this.props;
      if (appIsLoading) {
          return <Spinner size="large" />;
      }
      return (
          <Application>
              <RenderIf isTrue={user !== null && !isUnconfirmedRoute()}>
                  <RenderIf isTrue={!isSidebarHidden}>
                      <div
                          className="react-rainbow-admin-app_backdrop"
                          role="presentation"
                          onClick={toogleSidebar}
                      />
                  </RenderIf>
                  <div className={this.getSidebarClassNames()}>
                      <Sidebar
                          className="react-rainbow-admin-app_sidebar"
                          selectedItem={selectedItem}
                          onSelect={this.handleOnSelect}
                      >
                          <SidebarItem
                              className="react-rainbow-admin-app_sidebar-item"
                              icon={<DashboardIcon />}
                              name="/"
                              label="Dashboard"
                          />
                          <SidebarItem
                              className="react-rainbow-admin-app_sidebar-item"
                              icon={<MedicalRecord />}
                              name="medical-record"
                              label="Dossiers medical"
                          />
                          <SidebarItem
                              className="react-rainbow-admin-app_sidebar-item smallIcon"
                              icon={<Settings />}
                              name="permissions"
                              label="Permissions d'accés"
                          />
                          <SidebarItem
                              className="react-rainbow-admin-app_sidebar-item"
                              icon={<MessageIcon />}
                              name="messages"
                              label="Messagerie"
                          />
                          <SidebarItem
                              className="react-rainbow-admin-app_sidebar-item smallIcon"
                              icon={<VideoChat />}
                              name="teleconsultation"
                              label="Téléconsultation"
                          />
                      </Sidebar>
                      <RenderIf isTrue={openSavingAlert}>
                          <SavingAlertModal
                              open={openSavingAlert}
                              handleClose={this.handleSavingAlert}
                              handleSubmit={this.goToNextRoute}
                          />
                      </RenderIf>
                      <RenderIf isTrue={!isSidebarHidden}>
                          <div className="react-rainbow-admin-app_sidebar-back-button-container">
                              <ButtonIcon
                                  onClick={toogleSidebar}
                                  size="large"
                                  icon={(
                                      <FontAwesomeIcon
                                          className="react-rainbow-admin-app_sidebar-back-button-icon"
                                          icon={faArrowLeft}
                                      />
                                  )}
                              />
                          </div>
                      </RenderIf>
                  </div>
                  <div className="react-rainbow-admin-app_router-container">
                      <Routes />
                      <Snackbar {...snackbar} />
                  </div>
                  <SectionHeading onToogleSidebar={toogleSidebar} />
              </RenderIf>
              <RenderIf isTrue={user === null || isUnconfirmedRoute()}>
                  <Routes />
                  <Snackbar {...snackbar} />
              </RenderIf>
          </Application>
      );
  }
}

App.propTypes = {
    toogleSidebar: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    isSidebarHidden: PropTypes.bool.isRequired,
    user: PropTypes.object,
    checkAuthAsync: PropTypes.func.isRequired,
    appIsLoading: PropTypes.bool.isRequired,
    snackbar: PropTypes.object.isRequired,
};

App.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    ...state.app,
    user: state.auth.user,
    appIsLoading: state.loading.appIsLoading,
    snackbar: state.snackbar,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        toogleSidebar,
        navigate,
        checkAuthAsync,
    },
    dispatch,
);

export default InternetDetector(connect(stateToProps, dispatchToProps)(App));
