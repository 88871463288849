import React from 'react';
import PropTypes from 'prop-types';

export default function SignPost(props) {
    const {
        className,
        style,
    } = props;
    return (
        <svg className={className} style={style} height="19px" width="18px" version="1.1" viewBox="0 0 18 19">
            <g id="new-pages" fill="none" stroke="none" strokeWidth="1">
                <g id="admin-forms" transform="translate(-176.000000, -843.000000)">
                    <g id="Group-16" transform="translate(162.000000, 810.000000)">
                        <g id="sign-post" transform="translate(14.000000, 33.465986)">
                            <path id="Path" d="M9.33333333,0.350912281 C8.75192982,0.350912281 8.28070175,0.822140351 8.28070175,1.40354386 L8.28070175,16.2105614 L10.3859649,16.2105614 L10.3859649,1.40354386 C10.3859649,0.822140351 9.91473684,0.350912281 9.33333333,0.350912281 Z" fill="#A4A7B5" />
                            <path id="Path" d="M17.6140351,4.42108772 L15.6792982,6.06880702 C15.5680702,6.13863158 15.4389474,6.17547368 15.3073684,6.17547368 L10.3859649,6.17547368 L8.28070175,6.17547368 L7.57894737,6.17547368 C7.19122807,6.17547368 6.87719298,5.8614386 6.87719298,5.4737193 L6.87719298,3.36845614 C6.87719298,2.98073684 7.19122807,2.66670175 7.57894737,2.66670175 L8.28070175,2.66670175 L10.3859649,2.66670175 L15.3073684,2.66670175 C15.4389474,2.66670175 15.5680702,2.70354386 15.6792982,2.77336842 L17.6140351,4.42108772 Z" fill="#01B6F5" />
                            <path id="Path" d="M12.4912281,16.9123158 L12.4912281,17.6140702 L6.1754386,17.6140702 L6.1754386,16.9123158 C6.1754386,16.5245965 6.48947368,16.2105614 6.87719298,16.2105614 L8.28070175,16.2105614 L10.3859649,16.2105614 L11.7894737,16.2105614 C12.177193,16.2105614 12.4912281,16.5245965 12.4912281,16.9123158 Z" fill="#009ACF" />
                            <path id="Path" d="M11.0877193,8.98249123 L11.0877193,11.0877544 C11.0877193,11.4754737 10.7736842,11.7895088 10.3859649,11.7895088 L8.28070175,11.7895088 L2.65754386,11.7895088 C2.52596491,11.7895088 2.39684211,11.7526667 2.28561404,11.6828421 L0.350877193,10.0351228 L2.28561404,8.38740351 C2.39684211,8.31757895 2.52596491,8.28073684 2.65754386,8.28073684 L8.28070175,8.28073684 L10.3859649,8.28073684 C10.7736842,8.28073684 11.0877193,8.59477193 11.0877193,8.98249123 Z" fill="#01B6F5" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

SignPost.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
SignPost.defaultProps = {
    className: undefined,
    style: undefined,
};
