/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Modal, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';

const textStyles = {
    textAlign: 'center',
    fontSize: 15,
    padding: '0 16px',
};

const DeleteDialog = (props) => {
    const {
        open, handleClose, handleSubmit, data, modalTitle, contentText,
    } = props;
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                title={modalTitle}
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button label="Annuler" variant="brand" className="variant-brand rainbow-m-right_small" onClick={() => handleClose()} />
                        <Button label="Supprimer" variant="destructive" className="variant-destructive" onClick={() => handleSubmit(data._id)} />
                    </div>
                )}
            >
                <p style={textStyles}>
                    {contentText}
                </p>

            </Modal>
        </div>
    );
};


DeleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    data: PropTypes.object,
};

DeleteDialog.defaultProps = {
    data: {

        id: '',
    },
};

export default DeleteDialog;
