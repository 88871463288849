import React from 'react';
import PropTypes from 'prop-types';

export default function Smoke(props) {
    const {
        className,
        style,
    } = props;
    return (
        <img className={className} alt="" src="/assets/icons/smoke.svg" style={style} xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" />

    );
}

Smoke.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Smoke.defaultProps = {
    className: undefined,
    style: undefined,
};
