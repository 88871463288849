import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { Checked } from '../../components/icons';


const parseDate = (date) => {
    if (date.ts) {
        return DateTime.fromMillis(date.ts).toFormat(
            "dd/MM/yyyy 'à' HH':'mm ",
        );
    } if (typeof date === 'number') {
        return DateTime.fromMillis(date).toFormat(
            "dd/MM/yyyy 'à' HH':'mm ",
        );
    }
    return date;
};

export default function SentText({ isUser, isChecked, date }) {
    const getCheckedClassName = () => classnames({
        'react-rainbow-admin-messages_message--checked': isChecked,
    });

    if (isUser) {
        return (
            <div className="react-rainbow-admin-messages_message--sent">
                <span>{parseDate(date)}</span>
                <Checked className={getCheckedClassName()} />
            </div>
        );
    }
    return <span className="react-rainbow-admin-messages_message--sent-date">{parseDate(date)}</span>;
}

SentText.propTypes = {
    isUser: PropTypes.bool,
    isChecked: PropTypes.bool,
    date: PropTypes.string,
};

SentText.defaultProps = {
    isUser: false,
    isChecked: false,
    date: Date.now(),
};
