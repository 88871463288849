function validate(values) {
    const {
        email, password, emailConfirmation, passwordConfirmation,
        firstName, lastName, nationality,
    } = values;


    const errors = {};

    if (!nationality) {
        errors.nationality = 'Veuillez selectionnez votre pays';
    }
    if (!email) {
        errors.email = 'Veuillez insérer votre email';
    }
    if (!firstName) {
        errors.firstName = 'Veuillez insérer votre prénom';
    }
    if (!lastName) {
        errors.lastName = 'Veuillez insérer votre nom';
    }
    if (!emailConfirmation) {
        errors.emailConfirmation = 'Veuillez confirmer votre email';
    }
    if (email !== emailConfirmation) {
        errors.emailConfirmation = 'Emails incompatibles';
    }
    if (!password) {
        errors.password = 'Veuillez insérer votre mot de passe';
    }
    if (password && password.length < 6) {
        errors.password = 'Votre mot de passe doit contenir au moins 6 caractères';
    }
    if (!passwordConfirmation) {
        errors.passwordConfirmation = 'Veuillez confirmer votre mot de passe';
    }
    if (password !== passwordConfirmation) {
        errors.passwordConfirmation = 'Mot de passes incompatibles';
    }
    return errors;
}

export default validate;
