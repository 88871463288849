/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ButtonIcon,
    AvatarMenu,
    Avatar,
    MenuItem,
    MenuDivider,
    ButtonMenu,
} from 'react-rainbow-components/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import {
    MessageIcon,
    BarsIcon,
    NotificationIcon,
    PersonIcon,
} from '../icons';
import Notification from './notification';
import { disconnectAsync } from '../../redux/actions/auth';
import { API_PHOTOS } from '../../config';
import history, { navigateTo } from '../../history';
import { setNotificationsAsync, deleteNotifications } from '../../redux/actions/notifications';
import { setTelecListAsync } from '../../redux/actions/teleconsultation';
import { removeNotification } from '../../api/notifications';
import './styles.css';


const SectionHeading = (props) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const { user, setNotificationsAsync } = props;
    useEffect(() => {
        let unsubscribe;
        if (user) {
            unsubscribe = setNotificationsAsync(user._id);

            setProfilePicture(`${API_PHOTOS}/load/${user._id}/profile/thumbnail/profile-picture.webp?${Date.now()}`);
        }

        return () => {
            if (user) {
                unsubscribe();
            }
        };
    }, [user]);


    const handleNotificationClick = async (notif) => {
        const { setTelecListAsync } = props;
        switch (notif.type) {
            case 'teleconsultation':
                await removeNotification(notif._id, { seen: true });
                navigateTo('teleconsultation');
                if (history.location.pathname === '/teleconsultation') {
                    setTelecListAsync();
                }
                break;
            default: break;
        }
    };

    const mapNotifications = () => {
        const { notifications } = props;
        return notifications.map(notif => (
            <MenuItem
                onClick={() => { handleNotificationClick(notif); }}
                label={(
                    <Notification
                        title={notif.content} />
                )} />
        ));
    };

    const {
        onToogleSidebar, disconnectAsync, notifications, deleteNotifications,
        unseenMessages,
    } = props;
    return (
        <div>
            {user ? (
                <header className="react-rainbow-admin_header rainbow-position_fixed rainbow-flex rainbow-align_center rainbow-p-horizontal_large rainbow-background-color_white">
                    <img src="/assets/images/logo_dark.png" alt="Katomi logo" className="react-rainbow-admin_header-logo" />
                    <section className="rainbow-flex rainbow-align_center react-rainbow-admin_header-actions">
                        <div className="notif-container">
                            <div className="rainbow-flex notif-messages-icon" style={{ position: 'relative' }}>
                                <p className="notif-icon notif-messages-icon-text" style={{ color: '#01b6f5' }}>
                                    {unseenMessages.length}
                                </p>
                                <ButtonIcon
                                    size="large"
                                    onClick={() => { navigateTo('/messages'); deleteNotifications(unseenMessages); }}
                                    icon={<MessageIcon className="react-rainbow-admin_header-github-icon" />}
                                />
                            </div>

                            <div className="rainbow-flex" style={{ position: 'relative', left: 16 }}>
                                <p className="notif-icon" style={{ color: '#01b6f5' }}>{notifications.length}</p>
                                {' '}
                                <ButtonMenu
                                    className="rainbow-m-right_large react-rainbow-admin_header-button-notification"
                                    menuAlignment="right"
                                    buttonVariant="base"
                                    assistiveText="1"
                                    buttonSize="large"
                                    icon={<NotificationIcon style={{ width: 32, height: 32 }} />}>
                                    <MenuItem label={`Notifications ${notifications.length}`} variant="header" />

                                    {mapNotifications()}
                                </ButtonMenu>
                            </div>
                        </div>

                        <AvatarMenu
                            src={profilePicture}
                            icon={<PersonIcon />}
                            initials={`${user.lastName[0]}${user.firstName[0]}`}
                            assistiveText={` ${user.lastName} ${user.firstName}`}
                            title={` ${user.lastName} ${user.firstName}`}
                            menuAlignment="right"
                            menuSize="small">
                            <li className="rainbow-p-horizontal_small rainbow-align_center rainbow-flex">
                                <Avatar
                                    src={profilePicture}
                                    icon={<PersonIcon />}
                                    initials={`${user.lastName[0]}${user.firstName[0]}`}
                                    assistiveText={` ${user.lastName} ${user.firstName}`}
                                    title={` ${user.lastName} ${user.firstName}`}
                                    size="small" />
                                <div className="rainbow-m-left_x-small">
                                    <p className="rainbow-fot-size-text_medium rainbow-color_dark-1">{`${user.lastName} ${user.firstName}`}</p>
                                    <p className="rainbow-font-size-text_small rainbow-color_gray-3">{user.speciality}</p>
                                </div>
                            </li>
                            <MenuDivider variant="space" />
                            <MenuItem
                                label="Profil"
                                icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                iconPosition="left"
                                onClick={() => { navigateTo('/profile'); }}
                            />
                            <MenuItem
                                label="Déconnexion"
                                icon={<FontAwesomeIcon icon={faPowerOff} />}
                                iconPosition="left"
                                onClick={() => { disconnectAsync(); }}
                            />
                        </AvatarMenu>


                    </section>
                    <ButtonIcon
                        onClick={onToogleSidebar}
                        className="react-rainbow-admin_header-hamburger-button"
                        size="large"
                        icon={<BarsIcon />} />
                </header>
            ) : null}
        </div>
    );
};

SectionHeading.propTypes = {
    setNotificationsAsync: PropTypes.func.isRequired,
    onToogleSidebar: PropTypes.func,
    disconnectAsync: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    notifications: PropTypes.array,
    deleteNotifications: PropTypes.func.isRequired,
    unseenMessages: PropTypes.array.isRequired,
    setTelecListAsync: PropTypes.func.isRequired,
};

SectionHeading.defaultProps = {
    onToogleSidebar: () => {},
    notifications: [],
};


const stateToProps = state => ({
    user: state.auth.user,
    notifications: state.notifications.notifications,
    unseenMessages: state.notifications.unseenMessages,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        disconnectAsync,
        setNotificationsAsync,
        deleteNotifications,
        setTelecListAsync,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(SectionHeading);
