import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
    RadioGroup, Button,
} from 'react-rainbow-components/components';
import { PAYMENT_PENDING, REJECTED } from '../../config';
import './styles.css';

const options = [
    { label: 'Accepter', value: PAYMENT_PENDING },
    { label: 'Refuser', value: REJECTED },
];


const TelecRespondModal = (props) => {
    const { open, handleClose, handleSubmit } = props;
    const [selectedOption, setSelectedOption] = useState();
    return (
        <div>
            <Modal
                isOpen={open}
                id="modal-19"
                onRequestClose={handleClose}
                style={{ maxWidth: '55%', width: 'auto', padding: 20 }}
                title="Répondre a une invitation de téléconsultation"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button label="Annuler" variant="brand" className="variant-brand" onClick={() => handleClose()} />
                        <Button label="Valider" variant="brand" className="variant-brand" onClick={() => handleSubmit(selectedOption)} />
                    </div>
                )}
            >
                <RadioGroup
                    options={options}
                    value={selectedOption}
                    onChange={(e) => { setSelectedOption(e.target.value); }}
                />
            </Modal>
        </div>

    );
};

TelecRespondModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


export default (TelecRespondModal);
