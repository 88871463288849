import { db } from '../firebase';

const getNotifications = (userId, callback) => {
    const unsub = db.collection('notifications')
        .where('receiver', '==', userId)
        .onSnapshot((querySnapshot) => {
            callback(querySnapshot);
        });
    return unsub;
};

const removeMessagesNotifications = async (messagesEntry) => {
    messagesEntry.map(async (id) => {
        await db
            .collection('notifications')
            .doc(id)
            .delete();
    });
};


const updateNotification = async (id, data) => {
    await db
        .collection('notifications')
        .doc(id)
        .set(data, { merge: true });
};

const setSeenOnMessage = async (conversationId) => {
    const docs = await db
        .collection('notifications')
        .where('conversationId', '==', conversationId)
        .get();
    docs.forEach(async (doc) => {
        updateNotification(doc.id, { seen: true });
    });
};

const removeNotification = async (id) => {
    await db
        .collection('notifications')
        .doc(id)
        .delete();
};

const createNotification = async (data) => {
    await db.collection('notifications').add(data);
};

export {
    getNotifications,
    updateNotification,
    removeNotification,
    createNotification,
    setSeenOnMessage,
    removeMessagesNotifications,
};
