import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-rainbow-components';

const prepareColor = (variant) => {
    switch (variant) {
        case 'danger': return '#ed5565';
        case 'success': return '#1ad1a3';
        case 'brand': return '#01b8f7';
        case 'warning': return '#ffce54';
        default: return '#01b8f7';
    }
};

const CardHeader = ({ title, variant }) => (
    <div className="dashboard-card-header" style={{ backgroundColor: prepareColor(variant) }}>{title}</div>
);

CardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf({
        danger: PropTypes.string,
        success: PropTypes.string,
        brand: PropTypes.string,
        warning: PropTypes.string,
    }).isRequired,
};

const bodyStyles = {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginTop: 10,
};

const DashboardCard = (props) => {
    const {
        title, buttonLabel, imageSRC, variant, secondButtonLabel,
        handleClick1, handleClick2,
    } = props;

    return (
        <Card className="rainbow-flex rainbow-flex_column" style={{ alignItems: 'center', maxWidth: '48%', flex: 1 }}>
            <CardHeader title={title} variant={variant} />
            <div className="rainbow-flex rainbow-flex_column" style={bodyStyles}>
                <img
                    style={{ width: 50, height: 50 }}
                    alt={title}
                    src={imageSRC}
                />
                <Button
                    label={buttonLabel}
                    onClick={handleClick1}
                    variant="brand"
                    className="variant-brand rainbow-m-vertical_medium"
                />
                {secondButtonLabel ? (
                    <Button
                        label={secondButtonLabel}
                        variant="success"
                        onClick={handleClick2}
                        className="variant-success rainbow-m-bottom_medium"
                    />

                ) : null}
            </div>
        </Card>
    );
};

DashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    imageSRC: PropTypes.string.isRequired,
    variant: PropTypes.oneOf({
        danger: PropTypes.string,
        success: PropTypes.string,
        brand: PropTypes.string,
        warning: PropTypes.string,
    }).isRequired,
    secondButtonLabel: PropTypes.string,
    handleClick1: PropTypes.func.isRequired,
    handleClick2: PropTypes.func,
};

DashboardCard.defaultProps = {
    secondButtonLabel: undefined,
    handleClick2: () => {},
};

export default DashboardCard;
