/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    Badge,
    ButtonIcon,
    Card,
    Avatar,
} from 'react-rainbow-components';
import UserField from './userField';
import { PersonIcon } from '../../components/icons';
import { addImages } from '../../api/auth';
import { setSnackbar } from '../../redux/actions/snackbar';
import { updateAsync } from '../../redux/actions/auth';
import { API_PHOTOS } from '../../config';
import ImageCrop from '../../components/ImageCrop';
import './styles.css';


class Profile extends React.Component {
  state = {
      photoURL: null,
      readOnly: true,
      cropOpen: false,

  };

  componentDidMount() {
      const { user } = this.props;
      if (user) {
          this.setState({
              fullName: `${user.lastName} ${user.firstName}`, phoneNumber: user.phoneNumber, address: user.address, email: user.email,
          });
      }
  }

  handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
  }


  handleSubmit = () => {
      const {
          fullName, address, email, phoneNumber, readOnly,
      } = this.state;
      const { user, updateAsync } = this.props;

      const splittedFullName = fullName.split(' ');
      const lastName = splittedFullName[0];
      let firstName = '';
      for (let i = 1; i < splittedFullName.length; i += 1) {
          firstName += splittedFullName[i];
      }
      const body = {
          firstName, lastName, address, email, phoneNumber,
      };
      updateAsync(body, null, user._id);
      this.setState({ readOnly: !readOnly });
  }

  handleCapture = async (event) => {
      //   const { user, setSnackbar, updateAsync } = this.props;
      const { target } = event;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
          this.setState({ photoURL: e.target.result, cropOpen: true });
      };
  };


  updateProfilePicture = async (data) => {
      const { cropOpen } = this.state;
      const { updateAsync, user, setSnackbar } = this.props;
      try {
          await addImages(user._id, 'profile', data);
          this.setState({ cropOpen: !cropOpen });
          updateAsync({}, null, user._id);
      } catch (err) {
          setSnackbar("Une erreur s'est produite veuillez réessayer", 'error');
      }
  }

  render() {
      const { user } = this.props;
      let unchangableFullName = '';
      let userInitials = '';
      if (user) {
          unchangableFullName = `${user.lastName} ${user.firstName}`;
          userInitials = `${user.lastName[0]}${user.firstName[0]}`;
      }
      const { photoURL, readOnly } = this.state;
      const backupPhotoUrl = user
          ? `${API_PHOTOS}/load/${user._id}/profile/thumbnail/profile-picture.webp?${Date.now()}`
          : null;
      const userCardTitle = (
          <span
              className="rainbow-color_gray-4 rainbow-font-size-heading_small rainbow-flex rainbow-flex fullwidth"
              style={{ justifyContent: 'center' }}
          >
              <div
                  style={{
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                  }}
              >
                  <Avatar
                      size="medium"
                      src={backupPhotoUrl}
                      icon={<PersonIcon />}
                      className="profile-picture"
                      initials={userInitials}
                  />
                  <input
                      accept="image/*"
                      id="icon-button-photo-profile"
                      onChange={(e) => {
                          this.handleCapture(e, 'profile');
                      }}
                      type="file"
                      label="Modifier la photo de profile"
                      style={{ display: 'none' }}
                  />
                  <label
                      htmlFor="icon-button-photo-profile"
                      className="custom-input-add-file variant-brand"
                  >
                 Ajouter une photo de profil
                  </label>
              </div>
          </span>
      );

      const userCardFooter = (
          <div className="rainbow-align-content_space-between">
              <div className="rainbow-flex">
                  <UserField style={{ marginLeft: '1rem' }} label="Date d'éxpiration de l'abonnement" value="Pas d'abonnement" />
                  <div className="react-rainbow-admin-user-detail_vertical-divider" />
                  {/* <Button disabled label="Créditer mon solde sur mon compte bancaire" /> */}
              </div>
          </div>
      );

      const {
          email, fullName, phoneNumber, address, cropOpen,
      } = this.state;
      return (
          <div className="react-rainbow-admin-user-detail_wrapper">
              {user ? (
                  <div>
                      <div className="react-rainbow-admin-user-detail_cards-container">
                          <Card
                              title={userCardTitle}
                              footer={userCardFooter}
                              className="react-rainbow-admin-user-detail_card center-item-profile fullwidth"
                          >
                              <div className="react-rainbow-admin-user-detail_card-content">
                                  <span className="react-rainbow-admin-user-detail_user-name">
                                      {unchangableFullName}
                                  </span>
                                  <Badge
                                      variant="lightest"
                                      className="react-rainbow-admin-user-detail_user-status"
                                  >
                                      <FontAwesomeIcon icon={faCheck} pull="left" />
                                      <span>Compte Vérifié</span>
                                  </Badge>
                              </div>
                          </Card>
                          <Card
                              title="Identité"
                              actions={readOnly ? (
                                  <ButtonIcon
                                      variant="border"
                                      onClick={() => { this.setState({ readOnly: !readOnly }); }}
                                      icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                  />
                              ) : (
                                  <div style={{ display: 'flex' }}>
                                      {' '}
                                      <ButtonIcon
                                          variant="border"
                                          style={{ marginRight: 3 }}
                                          onClick={() => this.setState({ readOnly: !readOnly })}
                                          icon={<FontAwesomeIcon icon={faTimes} />} />
                                      <ButtonIcon
                                          onClick={this.handleSubmit}
                                          variant="border"
                                          icon={<FontAwesomeIcon icon={faCheck} />} />
                                      {' '}
                                  </div>
                              )}
                              className="react-rainbow-admin-user-detail_card"
                          >
                              <div className="react-rainbow-admin-user-detail_card-content react-rainbow-admin-user-detail_user-identity">
                                  <UserField
                                      label="Nom et Prénom"
                                      value={fullName}
                                      name="fullName"
                                      readOnly={readOnly}
                                      onChange={this.handleChange}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      label="Email"
                                      value={email}
                                      name="email"
                                      onChange={this.handleChange}
                                      readOnly={readOnly}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />

                                  <UserField
                                      label="Numéro de téléphone"
                                      value={phoneNumber}
                                      onChange={this.handleChange}
                                      name="phoneNumber"
                                      readOnly={readOnly}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                                  <UserField
                                      label="Adresse"
                                      value={address}
                                      name="address"
                                      onChange={this.handleChange}
                                      readOnly={readOnly}
                                      className="react-rainbow-admin-user-detail_user-identity_field"
                                  />
                              </div>
                          </Card>
                      </div>
                      {cropOpen ? (
                          <ImageCrop
                              open={cropOpen}
                              upImg={photoURL}
                              handleSubmit={this.updateProfilePicture}
                              handleClose={() => { this.setState({ cropOpen: false }); }} />
                      ) : null}

                  </div>
              ) : null}
          </div>
      );
  }
}

Profile.propTypes = {
    user: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    updateAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSnackbar,
        updateAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Profile);
