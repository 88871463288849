import {
    START_LOADING_DASHBOARD,
    STOP_LOADING_DASHBOARD,
    LOAD_DASHBOARD_DATA,
} from '../../actions/dashboard';

const initialState = {
    isLoading: false,
    nextTelec: '',
    lastTelec: '',
    subscriptionType: '',
    subscriptionExpires: '',

};

export default function dashboardData(state = initialState, action) {
    switch (action.type) {
        case LOAD_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        case START_LOADING_DASHBOARD:
            return { ...state, isLoading: action.payload };
        case STOP_LOADING_DASHBOARD:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}
