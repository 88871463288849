import React from 'react';
import { Spinner } from 'react-rainbow-components';
import './styles.css';

const DialogSpinner = () => (
    <div className="dialogSpinner-container">
        <p className="dialogSpinner-text">En cours de traitement ...</p>
        <Spinner />
    </div>
);


export default DialogSpinner;
