import { SET_SNACKBAR, CLOSE_SNACKBAR } from '../../actions/snackbar';

const initialState = {
    open: false,
    title: '',
    icon: '',
    description: '',
};

const snackbar = (state = initialState, action) => {
    switch (action.type) {
        case SET_SNACKBAR:
            return {
                open: true,
                title: action.payload.title,
                icon: action.payload.icon,
                description: action.payload.description,
            };
        case CLOSE_SNACKBAR:
            return {
                open: false, message: '', icon: '', description: '',
            };
        default:
            return state;
    }
};

export default snackbar;
