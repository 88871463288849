import React, { useState } from 'react';
import { Tabset, Tab, DateTimePicker } from 'react-rainbow-components/components';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import Calendar from '../../../components/Calendar';

const prepareEventName = (start, end) => `${start.toFormat('HH:mm')} à ${end.toFormat('HH:mm')}`;

const prepareConsultations = (events) => {
    const results = [];

    events.map((event) => {
        const start = DateTime.fromISO(event.date, {
            locale: 'fr',
        });
        const end = start.plus({ minutes: 60 });
        const consultName = prepareEventName(start, end);
        const result = {
            // eslint-disable-next-line no-underscore-dangle
            id: event._id,
            title: consultName,
            start: start.toJSDate(),
            end: end.toJSDate(),
        };

        return results.push(result);
    });

    return results;
};

const HandleTabs = (props, tab) => {
    // eslint-disable-next-line react/prop-types
    const {
        handleDateChange, date, docAvailability, handleSelectEvent,
    } = props;
    switch (tab) {
        case 'agenda':
            if (date) {
                handleDateChange(null);
            }
            return (
                <div style={{ margin: '2rem auto' }}>
                    {' '}
                    {docAvailability.length ? (
                        <Calendar
                            events={prepareConsultations(docAvailability)}
                            onSelectEvent={ev => handleSelectEvent(ev)}
                        />
                    ) : (
                        <h3>
                            {' '}
                            Votre médecin n’a pas encore fixé de créneaux de téléconsultation,
                            vous pouvez lui proposer une date de rendez-vous
                        </h3>
                    )}

                    {' '}
                </div>
            );
        case 'picker':
            return (
                <div style={{ margin: '2rem auto', width: '50%' }}>
                    <DateTimePicker
                        label="Date de la téléconsultation"
                        name="date"
                        minDate={new Date((new Date()).valueOf() + 86400000)}
                        onChange={(value) => { handleDateChange(value.getTime()); }}
                        value={date ? new Date(date) : null}
                    />
                </div>
            );
        default:
            return null;
    }
};


HandleTabs.propTypes = {
    handleDateChange: PropTypes.func.isRequired,
    handleSelectEvent: PropTypes.func.isRequired,
    date: PropTypes.object.isRequired,
    docAvailability: PropTypes.array.isRequired,
};

const SelectDate = (props) => {
    const [tab, setTab] = useState('agenda');

    return (
        <div>
            <Tabset
                id="tabset-5"
                onSelect={(ev, tab) => {
                    setTab(tab);
                }}
                activeTabName={tab}
            >
                <Tab
                    label="Disponibilité du médecin"
                    name="agenda"
                    id="agenda"
                    ariaControls="agenda"
                />
                <Tab
                    label="Proposer une autre date"
                    name="picker"
                    id="picker"
                    ariaControls="picker"
                />
            </Tabset>
            {HandleTabs(props, tab)}
        </div>

    );
};


export default SelectDate;
