/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PaymentForm from './form';
import DialogSpinner from '../../../../components/DialogSpinner';

const PaymentModal = (props) => {
    const {
        open, handleSubmit, handleClose, dialogIsLoading,
    } = props;
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">
            <Modal
                id="modal-9"
                isOpen={open}
                onRequestClose={handleClose}
                title="Paiement de la téléconsultation"
            >
                {dialogIsLoading ? (
                    <DialogSpinner />
                ) : (
                    <PaymentForm
                        handleCheckout={handleSubmit}
                    />
                ) }
            </Modal>
        </div>
    );
};

PaymentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    dialogIsLoading: PropTypes.bool.isRequired,
};


const stateToProps = state => ({
    dialogIsLoading: state.loading.dialogIsLoading,
});


export default connect(stateToProps)(PaymentModal);
