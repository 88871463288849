import React from 'react';
import {
    Card,
} from 'react-rainbow-components/components';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../styles.css';


const PhoneValidation = (props) => {
    const { phoneNumber, setPhoneNumber } = props;
    return (
        <div>

            <Card
                title="Contact"
                className="react-rainbow-admin-user-detail_card add-telec-phone-step"
            >

                <div className="phone-validation-form">
                    <p className="phone-validation-message is-jura">
                    Veuillez indiquer votre numéro de téléphone
                    afin que votre médecin puisse vous contacter
                    en cas nécessité ou de problème de connexion
                    </p>
                    <PhoneInput
                        placeholder="Insérer votre numéro"
                        value={phoneNumber}
                        onChange={setPhoneNumber} />

                </div>

            </Card>
        </div>

    );
};


PhoneValidation.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
};

export default PhoneValidation;
