/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Modal, Button, Textarea, RadioGroup, Input,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSnackbar } from '../../../redux/actions/snackbar';

const options = [
    { value: 're-fund', label: 'être remboursé' },
    { value: 're-scheduel ', label: 'Décaler cette téléconsultation pour une autre date' },
    { value: 'other', label: 'Autre' },
];

const ClaimTelecModal = (props) => {
    const [description, setDescription] = useState('');
    const [selectedOption, selectOption] = useState('');
    const [otherValue, setOtherValue] = useState('');

    const {
        open, handleClose, handleSubmit,
    } = props;

    // eslint-disable-next-line consistent-return
    const validateInputs = () => {
        const { setSnackbar } = props;
        if (description.trim().length < 5) {
            setSnackbar('Veuillez ajouter une description', 'error');
            return false;
        }
        if (!selectedOption.length) {
            setSnackbar('Veuillez séléctionnez ce que vous souhaitez avoir', 'error');
            return false;
        }

        if (selectedOption === 'other' && otherValue.trim().length < 2) {
            setSnackbar('Veuillez précisez ce que vous souhaitez avoir', 'error');
            return false;
        }
        if (selectedOption === 'other') {
            handleSubmit({
                selectedOption: otherValue,
                description,
            });
        } else {
            handleSubmit({
                selectedOption,
                description,
            });
        }
    };

    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                title="Réclamation"
                style={{ padding: '1rem 2rem' }}
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button label="Valider" variant="brand" className="variant-brand" onClick={() => validateInputs()} />
                    </div>
                )}
            >
                <Textarea
                    label="Description"
                    name="description"
                    value={description}
                    onChange={(e) => { setDescription(e.target.value); }}
                    rows={4}
                    placeholder="Décrivez le probléme que vous avez rencontré"
                    style={{ maxWidth: 700 }}
                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                />

                <RadioGroup
                    label="Je souhaite :"
                    options={options}
                    value={selectedOption}
                    onChange={(e) => { selectOption(e.target.value); setOtherValue(''); }}
                />
                {selectedOption === 'other' ? (
                    <Input
                        label="Que souhaitez vous?"
                        value={otherValue}
                        onChange={(e) => { setOtherValue(e.target.value); }}
                    />
                ) : null}
;

            </Modal>
        </div>
    );
};


ClaimTelecModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setSnackbar: PropTypes.string.isRequired,
};


const dispatchToProps = dispatch => bindActionCreators({
    setSnackbar,
}, dispatch);

export default connect(null, dispatchToProps)(ClaimTelecModal);
