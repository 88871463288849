import React from 'react';
import PropTypes from 'prop-types';

const titleStyles = {
    display: 'flex',
    margin: 'auto auto 1rem',
    marginTop: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',
    marginLeft: 'auto',
    justifyContent: 'center',
    fontSize: '1.3rem',
    letterSpacing: 1,

};

const TableTitle = ({ title }) => (
    <h1 style={titleStyles} className="is-jura">
        {title}
    </h1>
);

TableTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default TableTitle;
