import * as selectOptions from './select';

const API_ENDPOINT = 'https://api.katomi.co/v1';
// const API_ENDPOINT = 'http://localhost:8080/v1';
const API_AUTH = `${API_ENDPOINT}/doctor`;
const API_CHAT = `${API_ENDPOINT}/chat`;
const API_CONSULTATIONS = `${API_ENDPOINT}/consultations`;
const API_PHOTOS = `${API_ENDPOINT}/photos`;
const API_PATIENT_PHOTOS = `${API_ENDPOINT}/uploads`;
const API_TELECONSULTATION = `${API_ENDPOINT}/teleconsultations`;
const API_PATIENTS_USERS = `${API_ENDPOINT}/patients-users`;
const API_TELEEXPERTISE = `${API_ENDPOINT}/teleexpertise`;

// CONSULTATION TYPES
const TELECONSULTATION = 'Téléconsultation';

// TELECONSULTATION STATUS
const PENDING = "En attente d'une réponse";
const PAYMENT_PENDING = 'En attente de paiement';
const REJECTED = 'Réfuté';
const CHANGED = "Proposition d'une nouvelle date";
const PAYED = 'Payé';
const STARTED = 'started';
const ENDED = 'ended';

const errorCodes = {
    'auth/user-not-found': "Aucun compte n'est associé à cette adresse e-mail.",
    'auth/invalid-email': "L'adresse email saisie est invalide.",
    'auth/user-disabled':
      'Le compte associé à cette adresse e-mail a été désactivé.',
    'auth/wrong-password': 'Mot de passe incorrect. Veuillez réessayer.',
    'auth/user-token-expired':
      'Votre session a expirée. Veuillez vous reconnecter.',
    'auth/invalid-user-token':
      'Votre session a expirée. Veuillez vous reconnecter.',
    'auth/network-request-failed':
      'Impossible de communiquer avec le serveur. Veuillez vérifier votre connexion à internet.',
    'auth/email-already-in-use':
    'Vous avez déja un compte associé a cet adresse email',
};


// TODO TRANSLATE THIS TO FRENCH
const paymentErrorCodes = {
    "Your card's security code is incorrect.": 'Code de sécurité incorrect',
    'An error occurred while processing your card. Try again in a little bit.': 'Une erreur est survenue, veuillez réessayer plus tard',
    'Your card was declined. This transaction requires authentication.': 'mmm', // SCA
    'Your card was declined.': 'Votre carte à été rejetée',
    'Your card has insufficient funds.': 'Votre solde est insuffisant',
    'Your card has expired.': 'Votre carte à expirée',

};

export {
    API_AUTH,
    API_CONSULTATIONS,
    API_PHOTOS,
    API_PATIENTS_USERS,
    API_PATIENT_PHOTOS,
    API_CHAT,
    API_TELECONSULTATION,
    API_TELEEXPERTISE,
    TELECONSULTATION,

    PENDING,
    PAYMENT_PENDING,
    REJECTED,
    CHANGED,
    PAYED,
    STARTED,
    ENDED,

    selectOptions,
    errorCodes,
    paymentErrorCodes,
};
