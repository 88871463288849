/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Breadcrumb, Breadcrumbs, Button } from 'react-rainbow-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import detectRTC from 'detectrtc';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PlanificationTable from './planificationTable';
import { REJECTED, STARTED } from '../../config';
import {
    postLog, setTelecListAsync, deleteTelecAsync, updateTelecAsync,
} from '../../redux/actions/teleconsultation';
import { START_BC } from '../../redux/actions/teleconsultation/logsHelper';
import AddTelec from './add';
import StartTelec from './start';
import StartingTodayTelecs from './startingToday';
import HistoryTable from './historyTable';
import DeleteTelec from '../../components/DeleteModal';
import history from '../../history';

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

const getUserMeta = () => {
    const doctorMeta = {
        isWebsiteHasMicrophonePermissions: detectRTC.isWebsiteHasMicrophonePermissions,
        isWebsiteHasWebcamPermissions: detectRTC.isWebsiteHasWebcamPermissions,
        os: {
            osName: detectRTC.osName,
            osVersion: detectRTC.osVersion,

        },
        browser: detectRTC.browser,
    };
    return doctorMeta;
};

class Teleconsultations extends React.Component {
    state = {
        openStartModal: false,
        selectedTelec: null,
        openDeleteModal: false,
        openAddModal: false,
        deleteModalText: '',

    }

    componentDidMount() {
        const { setTelecListAsync } = this.props;
        setTelecListAsync();
        if (history.location.state && history.location.state.addFromDashboard) {
            this.setState({ openAddModal: true });
        }
    }

    handleStartModal = (telec) => {
        const { openStartModal } = this.state;
        const { updateTelecAsync } = this.props;
        if (telec) {
            postLog(telec._id, START_BC, null, getUserMeta());
            updateTelecAsync(telec._id, { status: STARTED });
        }
        this.setState({
            selectedTelec: telec,
            openStartModal: !openStartModal,
        });
    }

    handleAddModal = () => {
        const { openAddModal } = this.state;
        this.setState({ openAddModal: !openAddModal });
    }


    prepareDeleteText = (telec) => {
        if (telec && telec.status === REJECTED) {
            return 'êtes vous sûre de vouloir supprimer cette téléconsultation';
        }
        return 'êtes vous sûre de vouloir annuler cette téléconsultation? \n votre medecin sera notifié par cette action';
    }

    handleDeleteModal = (telec) => {
        const { openDeleteModal } = this.state;
        this.setState({
            openDeleteModal: !openDeleteModal,
            selectedTelec: telec,
            deleteModalText: this.prepareDeleteText(telec),
        });
    }

    handleDelete = (id) => {
        const { deleteTelecAsync } = this.props;
        deleteTelecAsync(id);
        this.handleDeleteModal();
    }

    render() {
        const {
            openAddModal, openStartModal, selectedTelec,
            openDeleteModal, deleteModalText,
        } = this.state;


        return (
            <div className="patients-container">
                <div className="react-rainbow-admin-users_header-container is-jura">
                    <Breadcrumbs>
                        <Breadcrumb label="Téléconsultation" onClick={() => history.push('/teleconsultation')} />
                    </Breadcrumbs>
                </div>
                <StartingTodayTelecs
                    handleStart={this.handleStartModal}
                />

                <div style={{ marginBottom: '5rem' }}>

                    <Button variant="brand" className="add-purpose-button" onClick={() => { this.handleAddModal(); }}>
                        <FontAwesomeIcon icon={faPlus} className="rainbow-m-right_medium" />
                             Planifier une téléconsultation
                    </Button>
                    <PlanificationTable handleDelete={this.handleDeleteModal} />
                </div>

                <div style={{ marginBottom: '5rem' }}>
                    <HistoryTable handleDelete={this.handleDeleteModal} />

                </div>

                {openAddModal ? (
                    <AddTelec open={openAddModal} handleCloseModal={this.handleAddModal} />

                ) : null}

                {openStartModal ? (
                    <StartTelec
                        selectedTelec={selectedTelec}
                        open={openStartModal}
                        handleClose={this.handleStartModal}
                    />
                ) : null}

                {openDeleteModal ? (
                    <DeleteTelec
                        open={openDeleteModal}
                        handleClose={this.handleDeleteModal}
                        handleSubmit={this.handleDelete}
                        modalTitle="Supression d'une téléconsultation"
                        contentText={deleteModalText}
                        data={selectedTelec} />
                ) : null}

            </div>
        );
    }
}

Teleconsultations.propTypes = {
    setTelecListAsync: PropTypes.func.isRequired,
    deleteTelecAsync: PropTypes.func.isRequired,
    updateTelecAsync: PropTypes.func.isRequired,
};


const dispatchToProps = dispatch => bindActionCreators({
    setTelecListAsync,
    deleteTelecAsync,
    updateTelecAsync,
}, dispatch);

export default connect(null, dispatchToProps)(Teleconsultations);
