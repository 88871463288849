/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { API_PHOTOS, API_PATIENTS_USERS } from '../config';


const getStatus = async () => {
    const res = await axios.get(`${API_PATIENTS_USERS}/login`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const login = async (credentials) => {
    const res = await axios.post(`${API_PATIENTS_USERS}/login`, credentials, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const logout = async () => {
    const res = await axios.post(`${API_PATIENTS_USERS}/logout`, null, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const register = async (body) => {
    const res = await axios.post(`${API_PATIENTS_USERS}/create`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const grantPermission = async (body) => {
    try {
        const res = await axios.post(`${API_PATIENTS_USERS}/acces`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return res.data;
    } catch (err) {
        // console.log(err);
    }
};

// eslint-disable-next-line consistent-return
const revokePermission = async (body) => {
    try {
        const res = await axios.put(`${API_PATIENTS_USERS}/acces`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return res.data;
    } catch (err) {
        // console.log(err);
    }
};

const addImages = async (patientId, category, images) => {
    const data = new FormData();
    data.append('files', images);
    const res = await axios.post(
        `${API_PHOTOS}/upload/${patientId}/${category}`,
        data,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    return res.data;
};

const _uploadImages = (imagesFiles, patientId) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(imagesFiles).map((category) => {
        imagesFiles[category].map(async (image) => {
            await addImages(patientId, category, image);
        });
    });
};


const update = async (body, imagesFiles, patientId) => {
    if (imagesFiles && patientId) {
        _uploadImages(imagesFiles, patientId);
    }
    const res = await axios.put(`${API_PATIENTS_USERS}`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};


export {
    getStatus,
    register,
    addImages,
    login,
    logout,
    update,
    grantPermission,
    revokePermission,
};
