import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyC2Fp3FeWle-f8ZyLvzs3CzJ7uQBWTkkdU',
    authDomain: 'katomipatient.firebaseapp.com',
    databaseURL: 'https://katomipatient.firebaseio.com',
    projectId: 'katomipatient',
    storageBucket: 'katomipatient.appspot.com',
    messagingSenderId: '409678251678',
    appId: '1:409678251678:web:49e751bd97b2cc89',
};


const Firebase = firebase.initializeApp(firebaseConfig);
const db = Firebase.firestore();
const auth = Firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.NONE);

export { db, auth };


export default Firebase;
