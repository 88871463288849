function validate(values) {
    const { email, password } = values;
    const errors = {};
    if (!email) {
        errors.email = 'Veuillez insérer votre email';
    }
    if (!password) {
        errors.password = 'Veuillez insérer votre mot de passe';
    }
    return errors;
}

export default validate;
