import React from 'react';
import { Breadcrumb, Breadcrumbs } from 'react-rainbow-components';
import { navigateTo } from '../../history';
import ContentPage from './content';
import './styles.css';


const MedicalRecord = () => (
    <div className="patients-container">
        <div className="react-rainbow-admin-users_header-container">
            <Breadcrumbs className="is-jura" style={{ marginBottom: '3rem' }}>
                <Breadcrumb label="Votre dossier médical" onClick={() => navigateTo('/medical-record')} />
            </Breadcrumbs>
        </div>
        <ContentPage />
    </div>
);


export default (MedicalRecord);
