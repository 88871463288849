import React from 'react';
import { Modal, Button } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'react-rainbow-components/components/Input';
import { Email } from '../icons';

const PasswordRecovery = (props) => {
    const {
        open, handleClose, handleSubmit, handleChange,
    } = props;
    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                title="Récupération de votre mot de passe"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button label="Annuler" className="variant-brand" variant="brand" onClick={() => handleClose()} />
                        <Button label="Envoyer" className="variant-brand" variant="brand" onClick={() => handleSubmit()} />
                    </div>
                )}
            >
                <Field
                    className="rainbow-m-top_small rainbow-rainbow-forms_inputs-field"
                    icon={<Email />}
                    component={Input}
                    onChange={(e) => { handleChange(e); }}
                    name="emailRecovery"
                    label="Adresse Email"
                    required
                    placeholder="Veuillez saisir votre adresse email"
                    type="email" />
            </Modal>
        </div>
    );
};


PasswordRecovery.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default PasswordRecovery;
