import axios from 'axios';
import { API_TELECONSULTATION, API_CONSULTATIONS } from '../config';
import { db } from '../firebase';


const getDoctorAvailability = async (doctorId) => {
    const res = await axios.get(`${API_CONSULTATIONS}/dt/${doctorId}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};


const getUserStatus = async (userId, callback) => {
    db.collection('teleconsultations')
        .doc(userId)
        .onSnapshot((querySnapshot) => {
            callback(querySnapshot);
        });
};

const getTeleconsultations = async () => {
    const res = await axios.get(API_TELECONSULTATION, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const createTeleconsultation = async (data) => {
    const res = await axios.post(`${API_TELECONSULTATION}`, data, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const update = async (id, data) => {
    const res = await axios.put(`${API_TELECONSULTATION}/${id}`, data, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};


const remove = async (id) => {
    const res = await axios.delete(`${API_TELECONSULTATION}/${id}`, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
};

const payPlanedTelec = async (body) => {
    const res = await axios.post(`${API_TELECONSULTATION}/pay/planed`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};

const payAvailableTelec = async (body) => {
    const res = await axios.post(`${API_TELECONSULTATION}/pay/available`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};


const postLog = async (id, body) => {
    await axios.post(`${API_TELECONSULTATION}/${id}/log`, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export {
    getTeleconsultations,
    createTeleconsultation,
    update,
    remove,
    getUserStatus,
    getDoctorAvailability,
    payAvailableTelec,
    payPlanedTelec,
    postLog,
};
