/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-rainbow-components';
import 'react-image-crop/dist/ReactCrop.css';

const submitButtonStyles = {
    display: 'block',
    letterSpacing: 1,
    fontSize: '1.2rem',
    margin: '1rem auto',
};

const ImageCrop = (props) => {
    const [imgRef, setImgRef] = useState(null);
    const [crop, setCrop] = useState({
        width: 50, aspect: 1 / 1, x: 50, y: 50,
    });
    const [blobToUpload, setBlob] = useState(null);

    const onLoad = useCallback((img) => {
        setImgRef(img);
    }, []);


    const createCropPreview = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;

                setBlob(blob);
            }, 'image/jpeg');
        });
    };

    const makeClientCrop = async (crop) => {
        if (imgRef && crop.width && crop.height) {
            createCropPreview(imgRef, crop, 'newFile.jpeg');
        }
    };
    const {
        open, handleClose, handleSubmit, upImg,
    } = props;
    return (
        <Modal size="large" isOpen={open} onRequestClose={handleClose}>

            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={makeClientCrop}
            />
            <Button
                className="variant-brand"
                style={submitButtonStyles}
                variant="brand"
                label="Enregistrer"
                onClick={() => handleSubmit(blobToUpload)} />
        </Modal>
    );
};


ImageCrop.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    upImg: PropTypes.object.isRequired,
};

export default ImageCrop;
