import React from 'react';
import PropTypes from 'prop-types';

export default function CalendarIcon(props) {
    const {
        className,
        style,
    } = props;

    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g fill="none" fillRule="nonzero">
                <path fill="#E3E5ED" d="M64 17.766v38.952A7.282 7.282 0 0 1 56.717 64H7.282A7.281 7.281 0 0 1 0 56.718V17.766" />
                <path fill="#EA4243" d="M59.961 26.721H4.038A4.038 4.038 0 0 1 0 22.683v-8.38a4.038 4.038 0 0 1 4.038-4.038h55.923A4.038 4.038 0 0 1 64 14.303v8.38a4.038 4.038 0 0 1-4.039 4.038z" />
                <path fill="#FE4849" d="M59.961 22.542H4.038A4.038 4.038 0 0 1 0 18.504v-8.38a4.038 4.038 0 0 1 4.038-4.038h55.923A4.038 4.038 0 0 1 64 10.124v8.38a4.038 4.038 0 0 1-4.039 4.038z" />
                <path fill="#000" d="M18.67 3.82v7.261c0 1.055-.428 2.01-1.12 2.702a3.82 3.82 0 0 1-6.521-2.702v-7.26a3.82 3.82 0 1 1 7.64 0zM52.971 3.82v7.261c0 1.055-.428 2.01-1.119 2.702a3.82 3.82 0 0 1-6.521-2.702v-7.26a3.82 3.82 0 1 1 7.64 0z" />
                <path fill="#061C3F" d="M18.67 3.82v5.754a3.81 3.81 0 0 1-1.12 2.7 3.82 3.82 0 0 1-6.521-2.7V3.82a3.82 3.82 0 1 1 7.64 0zM52.971 3.82v5.754a3.81 3.81 0 0 1-1.119 2.7 3.82 3.82 0 0 1-6.521-2.7V3.82a3.82 3.82 0 1 1 7.64 0z" />
                <g fill="#061C3F">
                    <path fillOpacity=".203" d="M30.719 41.605c0 .935-.749 1.684-1.684 1.684h-6.32a1.677 1.677 0 0 1-1.685-1.684v-6.32c0-.936.748-1.685 1.684-1.685h6.32c.936 0 1.685.749 1.685 1.684v6.32z" />
                    <path d="M44.083 35.284c0-.935-.748-1.684-1.684-1.684h-6.32c-.936 0-1.685.749-1.685 1.684v6.32c0 .936.749 1.685 1.684 1.685h6.32c.937 0 1.685-.749 1.685-1.684v-6.32z" />
                    <path fillOpacity=".203" d="M57.113 35.284c0-.935-.749-1.684-1.684-1.684h-6.32c-.936 0-1.685.749-1.685 1.684v6.32c0 .936.749 1.685 1.684 1.685h6.32c.936 0 1.685-.749 1.685-1.684v-6.32zM30.719 48.147c0-.936-.749-1.684-1.684-1.684h-6.32c-.937 0-1.685.748-1.685 1.684v6.32c0 .936.748 1.685 1.684 1.685h6.32c.936 0 1.685-.749 1.685-1.684v-6.32zM44.083 48.147c0-.936-.748-1.684-1.684-1.684h-6.32c-.936 0-1.685.748-1.685 1.684v6.32c0 .936.749 1.685 1.684 1.685h6.32c.937 0 1.685-.749 1.685-1.684v-6.32zM17.689 48.147c0-.936-.749-1.684-1.684-1.684h-6.32c-.936 0-1.685.748-1.685 1.684v6.32c0 .936.749 1.685 1.684 1.685h6.32c.936 0 1.685-.749 1.685-1.684v-6.32z" />
                </g>
            </g>
        </svg>
    );
}

CalendarIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

CalendarIcon.defaultProps = {
    className: undefined,
    style: undefined,
};
