export const SET_SNACKBAR = 'SET_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';


export const showSnackbar = (title, icon, description) => ({
    type: SET_SNACKBAR,
    payload: { title, icon, description },
});

export const closeSnackbar = () => ({
    type: CLOSE_SNACKBAR,
});


export const setSnackbar = (title, icon, description) => (dispatch) => {
    dispatch(showSnackbar(title, icon, description));
    setTimeout(() => { dispatch(closeSnackbar()); }, 5000);
};
