/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'react-rainbow-components';
import { DateTime } from 'luxon';
import {
    PAYMENT_PENDING, CHANGED, PAYED,
} from '../../../config';

const TelecTableActions = (props) => {
    const {
        row, handleDeleteDialog, handleRespondDialog, handlePayment,
        handleCancel,
    } = props;

    const rowTS = DateTime.fromString(row.date, 'dd/MM/yyyy').ts;
    const isIncomingTelec = rowTS > Date.now();
    const secButtonLabel = isIncomingTelec && row.status === PAYED ? 'Annuler' : 'Supprimer';
    const disableRespondButton = isIncomingTelec && row.status !== CHANGED;
    const enablePaymentButton = isIncomingTelec && row.status === PAYMENT_PENDING;

    return (
        <div className="rainbow-flex rainbow-flex_wrap">
            {enablePaymentButton ? (
                <Button
                    label="Passer au paiement"
                    variant="brand"
                    className="button-action variant-brand"
                    onClick={() => { handlePayment(row); }} />

            ) : null}

            {disableRespondButton ? null : (
                <Button
                    disabled={disableRespondButton}
                    label="Répondre"
                    variant="success"
                    className="button-action variant-success"
                    onClick={() => { handleRespondDialog(row); }} />
            )}

            <Button
                label={secButtonLabel}
                variant="destructive"
                className="button-action variant-destructive"
                onClick={() => {
                    if (secButtonLabel === 'Annuler') {
                        handleCancel(row);
                    } else {
                        handleDeleteDialog(row);
                    }
                }} />

        </div>


    );
};

TelecTableActions.propTypes = {
    row: PropTypes.object.isRequired,
    handleDeleteDialog: PropTypes.func.isRequired,
    handleRespondDialog: PropTypes.func.isRequired,
    handlePayment: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


export default TelecTableActions;
