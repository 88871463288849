/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Modal, Button, CheckboxToggle,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';


const CancelTelecModal = (props) => {
    const [checkboxValue, setcheckboxValue] = useState(false);

    const {
        open, handleClose, handleSubmit,
    } = props;

    return (
        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                title="Annulation d'une téléconsultation"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            disabled={!checkboxValue}
                            label="Valider"
                            variant="brand"
                            className="variant-brand"
                            onClick={() => handleSubmit()} />
                    </div>
                )}
            >
                <div style={{ margin: 'auto 6rem' }}>
                    <div className="rainbow-flex rainbow-flex_column" style={{ marginBottom: '2rem' }}>
                        <p className="rainbow-color_brand"
                            style={{
                                fontSize: '1rem', fontWeight: '400', letterSpacing: 1, marginBottom: '1rem',
                            }}>
                        Notez bien:
                        </p>
                        <p>- Des frais d'annulation de 5€ s'imposent </p>
                        <p>- La durée de traitement de votre remboursement peut aller jusqu'a 48 heures </p>

                    </div>
                    <CheckboxToggle
                        label="Je souhaite annuler cette téléconsultation"
                        value={checkboxValue}
                        onChange={() => { setcheckboxValue(!checkboxValue); }}
                    />
                </div>

            </Modal>
        </div>
    );
};


CancelTelecModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


export default CancelTelecModal;
